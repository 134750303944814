<template>
    <svg width="217" height="185" viewBox="0 0 217 185" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle opacity="0.05" cx="108" cy="92.5" r="92" fill="#253858"/>
    <g filter="url(#filter0_bdd_6009_167135)">
    <rect x="31.7812" y="53.5176" width="154.563" height="98.2819" rx="1.93432" fill="white" fill-opacity="0.15" shape-rendering="crispEdges"/>
    </g>
    <g filter="url(#filter1_bd_6009_167135)">
    <rect x="28.0996" y="44.4238" width="161.928" height="102.965" rx="1.88497" fill="white" fill-opacity="0.15" shape-rendering="crispEdges"/>
    </g>
    <g filter="url(#filter2_dd_6009_167135)">
    <rect x="23" y="33.5" width="170.842" height="108.97" rx="3.69388" fill="white"/>
    </g>
    <g filter="url(#filter3_d_6009_167135)">
    <path d="M77 91.25V49.5C77 48.3954 77.8954 47.5 79 47.5H129.29L140 58.75V130.5C140 131.605 139.105 132.5 138 132.5H79C77.8954 132.5 77 131.605 77 130.5V91.25Z" fill="#F8F9FA"/>
    <path d="M129.29 47.5H79C77.8954 47.5 77 48.3954 77 49.5V91.25V130.5C77 131.605 77.8954 132.5 79 132.5H138C139.105 132.5 140 131.605 140 130.5V58.75M129.29 47.5L140 58.75M129.29 47.5V56.75C129.29 57.8546 130.185 58.75 131.29 58.75H140" stroke="#A4C3F3" stroke-linecap="round"/>
    </g>
    <circle cx="108.5" cy="92.5" r="18.5" fill="#A4C3F3"/>
    <path d="M109.544 90.9565C108.725 90.1377 107.614 89.6777 106.456 89.6777C105.298 89.6777 104.187 90.1377 103.368 90.9565L100.279 94.0444C99.4602 94.8636 99 95.9745 99 97.1329C99 98.2914 99.4602 99.4023 100.279 100.221C101.098 101.041 102.209 101.501 103.368 101.501C104.526 101.501 105.637 101.041 106.456 100.221L108 98.6775" stroke="#0052CC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M106.457 94.0443C107.276 94.8631 108.387 95.323 109.545 95.323C110.703 95.323 111.814 94.8631 112.633 94.0443L115.722 90.9563C116.541 90.1372 117.001 89.0262 117.001 87.8678C117.001 86.7094 116.541 85.5984 115.722 84.7793C114.903 83.9602 113.792 83.5 112.634 83.5C111.475 83.5 110.364 83.9602 109.545 84.7793L108.001 86.3233" stroke="#0052CC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <defs>
    <filter id="filter0_bdd_6009_167135" x="23.7421" y="45.4784" width="170.641" height="114.361" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feGaussianBlur in="BackgroundImageFix" stdDeviation="4.01957"/>
    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_6009_167135"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="1.81524"/>
    <feGaussianBlur stdDeviation="1.81524"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"/>
    <feBlend mode="normal" in2="effect1_backgroundBlur_6009_167135" result="effect2_dropShadow_6009_167135"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="4.01957"/>
    <feGaussianBlur stdDeviation="2.00978"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0"/>
    <feBlend mode="normal" in2="effect2_dropShadow_6009_167135" result="effect3_dropShadow_6009_167135"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_6009_167135" result="shape"/>
    </filter>
    <filter id="filter1_bd_6009_167135" x="20.3047" y="36.6289" width="177.518" height="118.556" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feGaussianBlur in="BackgroundImageFix" stdDeviation="3.89744"/>
    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_6009_167135"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="1.76009"/>
    <feGaussianBlur stdDeviation="1.76009"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>
    <feBlend mode="normal" in2="effect1_backgroundBlur_6009_167135" result="effect2_dropShadow_6009_167135"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_6009_167135" result="shape"/>
    </filter>
    <filter id="filter2_dd_6009_167135" x="0.8367" y="18.7245" width="215.168" height="153.296" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="7.38777"/>
    <feGaussianBlur stdDeviation="11.0816"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.04 0 0 0 0 0.168627 0 0 0 0 0.36 0 0 0 0.08 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6009_167135"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="1.84694"/>
    <feGaussianBlur stdDeviation="1.84694"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
    <feBlend mode="normal" in2="effect1_dropShadow_6009_167135" result="effect2_dropShadow_6009_167135"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_6009_167135" result="shape"/>
    </filter>
    <filter id="filter3_d_6009_167135" x="72.8061" y="41.1561" width="71.3878" height="93.3878" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="-2.15"/>
    <feGaussianBlur stdDeviation="1.84694"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6009_167135"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6009_167135" result="shape"/>
    </filter>
    </defs>
    </svg>
</template>
<script>
    export default {
        name: "linkActivityBanner"
    }
</script>
<style scoped></style>