<template>
  <AppSnackBar :message="notifyMessage" :notificationType="notifyType" v-if="isNotify"></AppSnackBar>

  <app-loader v-if="loading || actionLoadingStatus"></app-loader>

  <div v-if="audienceBreakdownListData?.length != 0 ||
    breakdownTypeFilterId != 0 ||
    searchText
    " class="dashboard-right-inner">
    <div class="bg-white p-20 d-flex box-shadow-1 position-relative z-index-1">
      <div class="left min-w-24">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_6009_156482)">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M22.5 12C22.5 17.7984 17.7984 22.5 12 22.5C6.20156 22.5 1.5 17.7984 1.5 12C1.5 6.20156 6.20156 1.5 12 1.5C17.7984 1.5 22.5 6.20156 22.5 12ZM21 12C21 7.03125 16.9688 3 12 3C7.03125 3 3 7.03125 3 12C3 16.9688 7.03125 21 12 21C16.9688 21 21 16.9688 21 12ZM13.5 9V6H10.5V9H13.5ZM13.5 16.5H15V18H9V16.5H10.5V12H9V10.5H13.5V16.5Z"
              fill="#0052CC" />
          </g>
          <defs>
            <clipPath id="clip0_6009_156482">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div class="right pl-15">
        <h6 class="mb-1 font-15 l-height-24 font-w-600 color-black">
          Audience metrics may exceed total campaign totals.
        </h6>
        <p class="mb-0 font-12 l-height-16 font-w-400 text-color">
          Recipients that are members of more than one included list/segment
          will have their actions counted once for each list/segment membership.
          As a result, summed metrics on this page may exceed campaign totals.
        </p>
      </div>
    </div>
    <div class="p-24 bg-white">
      <Grid ref="grid" :class="'max-height-100-251 audience-breakdown-table sorting-btn-th'"
        :data-items="audienceBreakdownListData" :take="take" :skip="skip" :total="total" :pageable="gridPageable"
        :columns="columns" @datastatechange="dataStateChange">
        <!-- :sortable="true" :sort="sort"
                @sortchange="sortChangeHandler" -->
        <GridNoRecords>
          No list or segments found matching your input.
        </GridNoRecords>
        <template v-slot:nameCell="{ props }">
          <td>
            {{ generateName(props.dataItem?.nameOfItem) }}
          </td>
        </template>
        <template v-slot:bounceCell="{ props }">
          <td>
            <span>
              {{
                this.percentageCalculation(
                  props.dataItem?.uniqueBounced,
                  props.dataItem?.uniqueSent
                )
              }}%
            </span>
            <span class="opacity-8">({{ props.dataItem?.uniqueBounced || 0 }} Recipients)</span>
          </td>
        </template>
        <template v-slot:openCell="{ props }">
          <td>
            <span>
              {{
                this.percentageCalculation(
                  props.dataItem?.uniqueOpened,
                  props.dataItem?.uniqueSent
                )
              }}%
            </span>
            <span class="opacity-8">({{ props.dataItem?.uniqueOpened || 0 }} Recipients)</span>
          </td>
        </template>
        <template v-slot:clicksCell="{ props }">
          <td>
            <span>
              {{
                this.percentageCalculation(
                  props.dataItem?.uniqueClicked,
                  props.dataItem?.uniqueSent
                )
              }}%
            </span>
            <span class="opacity-8">({{ props.dataItem?.uniqueClicked || 0 }} Recipients)</span>
          </td>
        </template>
        <template v-slot:revenueCell="{ props }">
          <td>
            <span> ${{ props.dataItem.revenue.toFixed(2) }} </span>
          </td>
        </template>
        <toolbar>
          <div class="search-box-wrap width-220 w-100-vsm me-3 mr-0-vsm">
            <span class="k-icon k-i-zoom"></span>
            <span class="k-textbox k-grid-search k-display-flex">
              <k-input :class="'mb-0 width-220 w-100-vsm'" placeholder="Search" :value="searchWord"
                @input="onSearch"></k-input>
            </span>
          </div>

          <div class="pe-3 pr-0-vsm flex-vsm-wrap w-100-vsm">
            <label class="pe-2 mb-0 w-100-vsm">List or Segment</label>
            <DropDownList :class="'w-100-vsm'" :style="{ width: '160px' }" :data-items="breakDownTypes"
              :text-field="'text'" :data-item-key="'id'" :value="selectedBreakDownType" @change="onChangeBreakdownType">
            </DropDownList>
          </div>

          <div class="ms-auto ml-unset-767 w-100-vsm">
            <div class="ms-auto ml-unset-767 w-100-vsm">
              <div class="d-flex w-100-vsm">
                <kbutton :class="'w-100-vsm'" @click="exportReport">Export</kbutton>
              </div>
            </div>
          </div>
        </toolbar>
      </Grid>
    </div>
  </div>

  <div v-if="audienceBreakdownListData?.length == 0 &&
    !searchText &&
    breakdownTypeFilterId == 0 &&
    !loading
    ">
    <div
      class="dashboard-content bg-white radius-5 min-height-calc-100-182 d-flex justify-content-center align-items-center">
      <div class="width-747 text-center">
        <component :is="'audienceBreakdownIcon'"></component>
        <p class="color-light2 mt-3 mb-5">
          No list or segment found matching <br />
          your search criteria.
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "./emailCampaignAudienceBreakdown.scss";
</style>

<script>
import { getAudienceBreakDownListService } from "@/services/clubDetails/emailCampaign.service";
import { Grid, GridToolbar, GridNoRecords } from "@progress/kendo-vue-grid";
import { Input } from "@progress/kendo-vue-inputs";
import { Button } from "@progress/kendo-vue-buttons";
import AppSnackBar from "../../../../common/AppSnackBar/AppSnackBar.vue";
import AppLoader from "@/components/common/AppLoader/AppLoader.vue";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { configuration } from "@/configurationProvider";
import { auth } from "@/services/auth.service";
import audienceBreakdownIcon from "@/components/svg-icons/audienceBreakdownIcon.vue";
import axios from "axios";
export default {
  name: "emailCampaignAudienceBreakdown",
  components: {
    Grid,
    toolbar: GridToolbar,
    GridNoRecords,
    "k-input": Input,
    kbutton: Button,
    AppSnackBar,
    "app-loader": AppLoader,
    DropDownList,
    audienceBreakdownIcon,
  },
  data: function () {
    return {
      loading: false,
      actionLoadingStatus: false,
      audienceBreakdownListData: [],
      campaignId: this.$route.params.campaignId,

      searchWord: "",
      searchText: "",

      gridPageable: {
        buttonCount: 5,
        info: true,
        type: "numeric",
        pageSizes: [10, 50, 100, 200, 500, 1000],
        previousNext: true,
      },

      total: 0,
      skip: 0,
      take: 50,
      timer: null,

      notifyMessage: "",
      notifyType: "",
      isNotify: false,

      sort: [{ field: "nameOfItem", dir: "asc" }],

      breakDownTypes: [
        { id: 0, text: "All", value: "All" },
        { id: 1, text: "List", value: "list" },
        { id: 2, text: "Segment", value: "Segment" },
      ],
      selectedBreakDownType: { id: 0, text: "All", value: "All" },
      breakdownTypeFilterId: 0,
    };
  },
  props: {
    campaignDetails: {
      type: Object,
      default: () => { },
    },
  },
  computed: {
    columns() {
      return [
        {
          field: "nameOfItem",
          title: "List or Segment",
          cell: "nameCell",
        },
        {
          field: "totalAudience",
          title: "Total Recipients",
        },
        {
          field: "strTotalBounced",
          title: "Bounce",
          cell: "bounceCell",
        },
        {
          field: "strTotalOpened",
          title: "Opens",
          cell: "openCell",
        },
        {
          field: "strTotalClicked",
          title: "Clicks",
          cell: "clicksCell",
        },
        {
          field: "strRevenue",
          title: "Revenue",
          cell: "revenueCell",
        },
      ];
    },
  },
  mounted() {
    const payload = {
      campaignId: this.campaignId,
      limit: this.take,
      offset: this.skip,
      breakdownType: this.selectedBreakDownType.id,
    };
    this.getAudienceBreakdownList(payload);
  },

  methods: {
    percentageCalculation(value, total) {
      if (value == 0 && total == 0) {
        return 0;
      } else {
        if (isFinite(value / total)) {
          return Math.floor((value / total) * 100);
        } else {
          return 0;
        }
      }
    },
    generateName(name) {
      // 25 character return + add ..
      if (name?.length > 25) {
        return name.substring(0, 25) + "..";
      } else {
        return name;
      }
    },
    sortChangeHandler: function (e) {
      this.sort = e.sort;
      let sortBy = "";
      let sortDir = "";

      if (e.sort.length > 0) {
        sortBy = e.sort[0].field;
        sortDir = e.sort[0].dir;
      } else {
        sortBy = "";
        sortDir = "";
      }

      let payload = {
        campaignId: this.campaignId,
        limit: this.take,
        offset: this.skip,
        searchQuery: this.searchWord,
        breakdownType: this.selectedBreakDownType.id,
      };

      if (sortBy && sortDir) {
        payload.orderBy = `${sortBy} ${sortDir}`;
      }

      this.getAudienceBreakdownList(payload);
    },
    getAudienceBreakdownList(payload, type) {
      this.loading = true;
      payload = {
        ...payload,
        clubId: this.$route.params.id
      };
      getAudienceBreakDownListService(payload)
        .then((res) => {
          this.loading = false;
          this.audienceBreakdownListData = res.data?.data?.items;
          this.total = res.data?.data?.size || 0;
          this.take = res.data?.data?.limit || 0;
          this.skip = res.data?.data?.offset || 0;
          this.filterReloader(payload);

          if (type == "resendEmail") {
            this.showNotification(
              "Email campaign sent to the recipient.",
              "success"
            );
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    filterReloader(payload) {
      if (payload.searchQuery) {
        this.searchText = payload.searchQuery;
      }
      if (payload.breakdownType) {
        this.breakdownTypeFilterId = payload.breakdownType;
      }
    },
    dataStateChange(event) {
      this.take = event.data.take;
      this.skip = event.data.skip;

      let payload = {
        campaignId: this.campaignId,
        limit: event.data.take,
        offset: event.data.skip,
        searchQuery: this.searchWord,
        breakdownType: this.selectedBreakDownType.id,
      };

      this.getAudienceBreakdownList(payload);
    },
    onChangeBreakdownType(e) {
      this.selectedBreakDownType = e.value;
      let payload = {
        campaignId: this.campaignId,
        limit: this.take,
        offset: this.skip,
        searchQuery: this.searchWord,
        breakdownType: this.selectedBreakDownType.id,
      };
      this.getAudienceBreakdownList(payload);
    },
    onSearch(e) {
      this.searchWord = e.value;
      this.isSearching = true;
      let payload = {
        campaignId: this.campaignId,
        limit: this.take,
        offset: 0,
        searchQuery: this.searchWord,
        breakdownType: this.selectedBreakDownType.id,
      };

      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.getAudienceBreakdownList(payload);
      }, 500);
    },
    async exportReport() {
      this.actionLoadingStatus = true;
      const payload = {
        campaignId: this.campaignId,
      };

      let token = null;

      if (configuration.enableIdentityServer) {
        token = await auth.getAccessToken();
      } else {
        token = localStorage.getItem("clubeez_session");
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      axios({
        url: `${configuration.apiBaseUrl}/api/v1/Campaign/export-campaign-audience-breakdown`,
        method: "POST",
        responseType: "blob",
        headers: headers,
        data: payload,
      })
        .then((response) => {
          this.actionLoadingStatus = false;

          let fileURL = window.URL.createObjectURL(new Blob([response.data]));
          let fileLink = document.createElement("a");
          fileLink.href = fileURL;
          let filename = `Campaign Audience Breakdown.xlsx`;
          fileLink.setAttribute("download", filename);
          document.body.appendChild(fileLink);
          fileLink.click();

          this.showNotification(
            "Email campaign audience breakdown exported.",
            "success"
          );
        })
        .catch((err) => {
          this.actionLoadingStatus = false;
        });
    },
    showNotification(message, type) {
      this.notifyMessage = message;
      this.notifyType = type;
      this.isNotify = true;
      setTimeout(
        () => (
          (this.notifyMessage = ""),
          (this.notifyType = "success"),
          (this.isNotify = false)
        ),
        4000
      );
    },
  },
};
</script>
