<div class="dashboard-right-inner">
  <Breadcrumb :id="$route.params.id" />
  <div class="max-height-100--60 overflow-y-auto">
    <div class="dashboard-header position-relative z-index-2">
      <div
        class="d-flex flex-wrap justify-content-between align-items-center pb-20"
      >
        <div class="w-100-vvsm">
          <div class="d-flex align-items-center mb-1">
            <h2 class="font-28 font-w-300 mb-0 me-3">
              {{getEmailCampaignAnalyticsData?.campaignName ?
              generateName(getEmailCampaignAnalyticsData?.campaignName) :
              generateName($route.query.campaignName) }}
            </h2>
            <span
              v-if="campaignStatus[getEmailCampaignAnalyticsData?.campaignStatus]"
              :class="['campaign-status font-12 radius-4',campaignStatusColor[getEmailCampaignAnalyticsData?.campaignStatus]]"
              >{{campaignStatus[getEmailCampaignAnalyticsData?.campaignStatus]}}</span
            >
          </div>
        </div>
        <div class="w-100-vvsm">
          <div class="d-flex justify-content-end mt-2 mt-xl-0">
            <kbutton
              :fill-mode="'flat'"
              :class="'ms-0 ms-sm-3 color-black px-13 pl-0-vsm'"
              :theme-color="'primary'"
              @click="doNavigateToCampaignHomepage(getEmailCampaignAnalyticsData?.campaignGroup)"
            >
              <span class="d-none d-sm-inline-block"
                >Return to {{getEmailCampaignAnalyticsData?.campaignGroup ==
                1?'Campaigns':'Flow'}}</span
              >
              <span class="d-inline-block d-sm-none"> Return </span>
            </kbutton>
            <kbutton
              :fill-mode="'outline'"
              :class="'ms-1 ms-md-3 h-38'"
              :theme-color="'primary'"
              @click="doNavigateToCampaignView"
            >
              View Campaign
            </kbutton>
          </div>
        </div>
      </div>
      <!-- {{getEmailCampaignAnalyticsData?.campaignType}} -->
      <template v-if="!getEmailCampaignAnalyticsLoading">
        <div
          v-if="getEmailCampaignAnalyticsData?.campaignType == 1 || !getEmailCampaignAnalyticsData?.campaignType && isCampaignExist"
          class="d-flex flex-wrap mt-10"
        >
          <div
            :class=" activeTab == tab.name  ? 'btn-active' : ''"
            :ref="tab.name"
            v-for="tab in tabNamesForRegularCampaign"
          >
            <kbutton
              :ref="tab.name"
              @click="switchTabHandlers(tab.name)"
              :theme-color="'primary'"
              :fill-mode="'flat'"
              :class="'radius-0 color-black'"
              >{{tab.value}}
            </kbutton>
          </div>
        </div>
        <div
          v-if="getEmailCampaignAnalyticsData?.campaignType == 2 && isCampaignExist"
          class="d-flex flex-wrap mt-10"
        >
          <div
            :class=" activeTab == tab.name  ? 'btn-active' : ''"
            :ref="tab.name"
            v-for="tab in tabNamesForAdvanceCampaign"
          >
            <kbutton
              :ref="tab.name"
              @click="switchTabHandlers(tab.name)"
              :theme-color="'primary'"
              :fill-mode="'flat'"
              :class="'radius-0 color-black'"
              >{{tab.value}}
            </kbutton>
          </div>
        </div>
      </template>
    </div>
    <div class="">
      <component
        :campaignDetails="getEmailCampaignAnalyticsData"
        :is="activeTab"
      ></component>
    </div>
  </div>
</div>
