<app-loader
  v-if="campaignPerformanceOverTimeLoader || campaignOverallPerformanceLoader || campaignPerformanceBenchmarkLoader || campaignPerformanceByLocationLoader || campaignClientDevicesLoader"></app-loader>
<div v-if="!getEmailCampaignAnalyticsLoading" class="p-24">
  <div class="advanced-reports-wrap">
    <div v-if="!getEmailCampaignAnalyticsLoading">
      <div v-if="campaignOverallPerformanceData" class="advanced-reports-box">
        <p class="mb-16 font-16 font-w-600">Overall Performance</p>
        <div class="overall-performance-card-all link-activity-card-all d-flex justify-content-start">
          <div v-for="(item, index) in overallPerformanceHtmlTreeObject" :class="item?.color"
            class="link-activity color-1">
            <div class="content w-100">
              <h4 class="link-activity-status">{{item?.title}}</h4>
              <div v-if="item?.id == 1" class="info d-flex align-items-center">
                <h2 class="mb-0">
                  {{campaignOverallPerformanceData?.uniqueSent || 0}}
                </h2>
              </div>
              <div v-if="item?.id == 2" class="info d-flex align-items-center">
                <h2 class="mb-0">
                  {{percentageCalculator(campaignOverallPerformanceData?.uniqueOpened,
                  campaignOverallPerformanceData?.uniqueDelivered)}}%
                </h2>
                <span class="link-activity-number ms-auto">{{campaignOverallPerformanceData?.uniqueOpened}} opens</span>
              </div>
              <div v-if="item?.id == 3" class="info d-flex align-items-center">
                <h2 class="mb-0">
                  {{percentageCalculator(campaignOverallPerformanceData?.uniqueClicked,
                  campaignOverallPerformanceData?.uniqueDelivered)}}%
                </h2>
                <span class="link-activity-number ms-auto">{{campaignOverallPerformanceData?.uniqueClicked}}
                  clicks</span>
              </div>
              <div v-if="item?.id == 4" class="info d-flex align-items-center">
                <h2 class="mb-0">
                  {{percentageCalculator(campaignOverallPerformanceData?.totalConversion,
                  campaignOverallPerformanceData?.uniqueDelivered)}}%
                </h2>
                <span class="link-activity-number ms-auto">{{campaignOverallPerformanceData?.totalConversion}}
                  conversions</span>
              </div>
              <div v-if="item?.id == 5" class="info d-flex align-items-center">
                <h2 class="mb-0">
                  ${{valuePerSendCalc(campaignOverallPerformanceData?.totalConversionValue,
                  campaignOverallPerformanceData?.totalConversion)}}
                </h2>
                <span class="link-activity-number ms-auto">{{campaignOverallPerformanceData?.totalConversion}}
                  conversions</span>
              </div>
              <div v-if="item?.id == 6" class="info d-flex align-items-center">
                <h2 class="mb-0">
                  {{percentageCalculator(campaignOverallPerformanceData?.uniqueBounced,
                  campaignOverallPerformanceData?.uniqueSent)}}%
                </h2>
                <span class="link-activity-number ms-auto">{{campaignOverallPerformanceData?.uniqueBounced}}
                  bounces</span>
              </div>
              <div v-if="item?.id == 7" class="info d-flex align-items-center">
                <h2 class="mb-0">
                  {{percentageCalculator(campaignOverallPerformanceData?.totalOpened,
                  campaignOverallPerformanceData?.totalDelivered)}}%
                </h2>
                <span class="link-activity-number ms-auto">{{campaignOverallPerformanceData?.totalOpened}} opens</span>
              </div>
              <div v-if="item?.id == 8" class="info d-flex align-items-center">
                <h2 class="mb-0">
                  {{percentageCalculator(campaignOverallPerformanceData?.totalClicked,
                  campaignOverallPerformanceData?.totalDelivered)}}%
                </h2>
                <span class="link-activity-number ms-auto">{{campaignOverallPerformanceData?.totalClicked}}
                  clicks</span>
              </div>
              <div v-if="item?.id == 9" class="info d-flex align-items-center">
                <h2 class="mb-0">
                  ${{campaignOverallPerformanceData?.totalConversionValue || 0}}
                </h2>
              </div>
              <div v-if="item?.id == 10" class="info d-flex align-items-center">
                <h2 class="mb-0">
                  ${{valuePerSendCalc(campaignOverallPerformanceData?.totalConversionValue,
                  campaignOverallPerformanceData?.totalSent)}}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="campaignPerformanceOverTimeData" class="advanced-reports-box">
        <div class="overall-performance-card-header d-flex justify-content-between align-items-center mb-32">
          <div class="left">
            <p class="mb-8 font-16 font-w-600">Performance Over Time</p>
            <p class="mb-0 font-12 font-w-400 text-color">
              {{selectedDropdownvalue?.message}}
            </p>
          </div>
          <div class="right d-flex align-items-center">
            <dropdownlist v-if="campaignDetails?.campaignType == 2" :class="'ml-16 w-198'"
              @open="onVariationDropdownOpen" :data-items="variationsData" :text-field="'variationNo'"
              :data-item-key="'id'" @change="handleVariationChange" :value="selectedVariation"
              :list-no-data-render="listNoDataRender" :filterable="true" @filterchange="variationFilterChange">
              <template v-slot:noDataFoundTemplate="">
                <custom :messageShow="'No Variations Found'" />
              </template>
            </dropdownlist>
            <div v-if="selectedDropdownvalue?.id == 5" class="d-flex date-range-sm">
              <div class="d-flex align-items-center ml-16">
                <label for="" class="mb-0">Start</label>
                <datePicker :class="'mb-0 w-198 ml-16'" :format="'dd-MMM-yyyy'" :default-value="new Date()"
                  @change="updateStartDate" :value="startDate">
                </datePicker>
              </div>
              <div class="d-flex align-items-center ml-16">
                <label for="" class="mb-0">End</label>
                <datePicker :class="'mb-0 w-198 ml-16'" :format="'dd-MMM-yyyy'" :default-value="new Date()"
                  @change="updateEndDate" :value="endDate">
                </datePicker>
              </div>
            </div>
            <dropdownlist :style="{}" :class="'ml-16 w-198'" :data-items="dropdownListData" :text-field="'text'"
              :data-item-key="'id'" @change="handledropdownSelection" :value="selectedDropdownvalue">
            </dropdownlist>
          </div>
        </div>
        <div class="overall-performance-card-all link-activity-card-all d-flex mb-8">
          <div v-for="(item, index) in performanceOverTimeHtmlTreeObject" :class="item?.color"
            class="link-activity color-1">
            <div class="content w-100">
              <h4 class="link-activity-status">{{item?.title}}</h4>
              <div v-if="item?.id == 1" class="info d-flex align-items-center">
                <h2 class="mb-0">
                  {{campaignPerformanceOverTimeData?.uniqueSent || 0}}
                </h2>
              </div>
              <div v-if="item?.id == 2" class="info d-flex align-items-center">
                <h2 class="mb-0">
                  {{percentageCalculator(campaignPerformanceOverTimeData?.uniqueOpened,
                  campaignPerformanceOverTimeData?.uniqueDelivered)}}%
                </h2>
                <span class="link-activity-number ms-auto">{{campaignPerformanceOverTimeData?.uniqueOpened}}
                  opens</span>
              </div>
              <div v-if="item?.id == 3" class="info d-flex align-items-center">
                <h2 class="mb-0">
                  {{percentageCalculator(campaignPerformanceOverTimeData?.uniqueClicked,
                  campaignPerformanceOverTimeData?.uniqueDelivered)}}%
                </h2>
                <span class="link-activity-number ms-auto">{{campaignPerformanceOverTimeData?.uniqueClicked}}
                  clicks</span>
              </div>
              <div v-if="item?.id == 4" class="info d-flex align-items-center">
                <h2 class="mb-0">
                  ${{campaignPerformanceOverTimeData?.totalConversionValue ||
                  0}}
                </h2>
              </div>
              <div v-if="item?.id == 5" class="info d-flex align-items-center">
                <h2 class="mb-0">
                  {{percentageCalculator(campaignPerformanceOverTimeData?.uniqueBounced,
                  campaignPerformanceOverTimeData?.uniqueSent)}}%
                </h2>
                <span class="link-activity-number ms-auto">{{campaignPerformanceOverTimeData?.uniqueBounced}}
                  bounces</span>
              </div>
            </div>
          </div>
        </div>
        <appLineChart :legendVisibility="false" :dataAnalytics="campaignPerformanceOverTimeData" :loader="false">
        </appLineChart>
      </div>
      <div class="advanced-reports-box">
        <p class="mb-16 font-16 font-w-600">Clients and Devices</p>
        <div v-if="campaignClientDevicesData?.mobile?.length != 0 || campaignClientDevicesData?.desktop?.length != 0">
          <div class="d-flex clients-devices-row">
            <div class="left-32">
              <div class="buttons-tab-menu d-flex mb-45">
                <kbutton @click="toggleFilterDeviceHandler('clientAndDevices', index)"
                  v-for="(item,index) in deviceFilterObject?.clientAndDevices"
                  :theme-color="item?.checked ? 'primary' : 'base'">{{item?.name}}
                </kbutton>
              </div>
              <div class="d-flex flex-column align-items-start clients-and-devices-chart mb-24">
                <Chart v-if="campaignClientDevicesData?.selected?.length != 0">
                  <ChartTooltip :render="'renderTooltip'">
                    <template v-slot:renderTooltip="{ props }">
                      <div v-if="props">
                        {{ props?.category }} {{ props.point.category }} ({{
                        Math.round(props.point.value) }}%)
                      </div>
                    </template>
                  </ChartTooltip>
                  <ChartTitle position="bottom" :text="''" />
                  <ChartLegend :position="'bottom'" />
                  <ChartSeries>
                    <ChartSeriesItem :type="'pie'" :data-items="campaignClientDevicesData?.selected" :field="'value'"
                      :category-field="'category'" />
                  </ChartSeries>
                </Chart>
                <div v-else>
                  <div class="ps-3 mb-4">
                    <component is="clientsAndDevices" class="h-340"></component>
                    <p class="max-w-220 mx-auto mb-0 mt-29 text-center text-color-2 font-14 l-height-24">
                      No analytics were generated for this section yet.
                    </p>
                  </div>
                </div>
                <p class="text-center text-color font-12 l-height-14 max-w-244 mb-0 me-auto"
                  v-if="campaignClientDevicesData?.selected?.length != 0">
                  Showing distribution of email clients and devices in a pie
                  chart
                </p>
              </div>
            </div>
            <div class="right-68 pl-46">
              <div>
                <Grid :class="'clients-and-devices-table'" ref="grid" :data-items="campaignClientDevicesData?.mobile"
                  :columns="columnForClientAndDevicesTypeMobile">
                  <GridNoRecords>
                    Not enough data at this time. Please try again later.
                  </GridNoRecords>
                  <template v-slot:categoryCell="{props}">
                    <td>
                      <div class="d-flex align-items-center">
                        <span class="flex-grow-1">{{props.dataItem?.category}}</span>
                      </div>
                    </td>
                  </template>
                  <template v-slot:percentageCell="{props}">
                    <td>
                      {{props.dataItem?.value}} %
                      ({{props.dataItem?.User_Per_Group}} 
                      {{ props.dataItem?.User_Per_Group > 1 ? 'Recipients' : 'Recipient' }})
                    </td>
                  </template>
                </Grid>
              </div>
              <br />
              <br />
              <div>
                <Grid :class="'clients-and-devices-table'" ref="grid" :data-items="campaignClientDevicesData?.desktop"
                  :columns="columnForClientAndDevicesTypeDesktop">
                  <GridNoRecords>
                    Not enough data at this time. Please try again later.
                  </GridNoRecords>
                  <template v-slot:categoryCell="{props}">
                    <td>
                      <div class="d-flex align-items-center">
                        <span class="flex-grow-1">{{props.dataItem?.category}}</span>
                      </div>
                    </td>
                  </template>
                  <template v-slot:percentageCell="{props}">
                    <td>
                      {{props.dataItem?.value}} %
                      ({{props.dataItem?.User_Per_Group}} 
                      {{ props.dataItem?.User_Per_Group > 1 ? 'Recipients' : 'Recipient' }})
                    </td>
                  </template>
                </Grid>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="pt-93 pb-122 text-center">
            <component is="clientsAndDevices"></component>
            <p class="max-w-220 max-w-220 mx-auto mb-0 mt-29 text-center text-color-2 font-16 l-height-24">
              No analytics were generated for this section yet.
            </p>
          </div>
        </div>
      </div>
      <div class="advanced-reports-box">
        <p class="mb-16 font-16 font-w-600">Performance by Locations</p>
        <div v-if="campaignPerformanceByLocationData?.length != 0">
          <div class="d-flex performance-by-locations-row">
            <div class="left-32">
              <div class="d-flex flex-column align-items-start clients-and-devices-chart mb-24">
                <Chart>
                  <ChartTooltip :render="'renderTooltip'">
                    <template v-slot:renderTooltip="{ props }">
                      <div v-if="props">
                        {{ props?.category }} {{ props.point.category }} ({{
                        Math.round(props.point.value) }}%)
                      </div>
                    </template>
                  </ChartTooltip>
                  <ChartTitle :position="'bottom'" :text="''" />
                  <ChartLegend :position="'bottom'" />
                  <ChartSeries>
                    <ChartSeriesItem :type="'pie'" :data-items="campaignPerformanceByLocationData" :field="'value'"
                      :category-field="'category'" />
                  </ChartSeries>
                </Chart>
                <p class="text-center text-color font-12 l-height-14 max-w-244 mb-0 me-auto">
                  Displaying distribution of locations in a pie chart.
                </p>
              </div>
            </div>
            <div class="right-68 pl-46">
              <div>
                <Grid :class="'performance-by-locations-table'" ref="grid" :data-items="campaignPerformanceByLocationData"
                  :columns="columnForPerformanceByLocations">
                  <GridNoRecords> No Data found. </GridNoRecords>
                  <template v-slot:categoryCell="{props}">
                    <td>
                      <div class="d-flex align-items-center">
                        <span class="flex-grow-1">{{props.dataItem?.category}}</span>
                      </div>
                    </td>
                  </template>
                  <template v-slot:percentageCell="{props}">
                    <td>
                      {{props.dataItem?.value}} %
                      ({{props.dataItem?.User_Per_Group}} 
                      {{ props.dataItem?.User_Per_Group > 1 ? 'Recipients' : 'Recipient' }})
                    </td>
                  </template>
                </Grid>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="pt-93 pb-122 text-center">
            <component is="performanceByLocation"></component>
            <p class="max-w-220 max-w-220 mx-auto mb-0 mt-29 text-center text-color-2 font-16 l-height-24">
              No analytics were generated for this section yet.
            </p>
          </div>
        </div>
      </div>
      <div class="advanced-reports-box">
        <div v-if="campaignPerformanceBenchmarkData?.length != 0">
          <p class="mb-16 font-16 font-w-600">Performance Benchmarks</p>
          <div class="d-flex performance-benchmarks-row">
            <div class="left-32">
              <div class="buttons-tab-menu d-flex mb-45">
                <kbutton :key="index" @click="toggleFilterDeviceHandler('performanceBenchmarks', index)"
                  v-for="(item,index) in deviceFilterObject?.performanceBenchmarks"
                  :theme-color="item?.checked ? 'primary' : 'base'">{{item?.name}}
                </kbutton>
              </div>
              <div class="clients-and-devices-chart mb-24">
                <Chart>
                  <ChartTooltip :format="'{0}%'" />
                  <ChartLegend :position="'bottom'" :orientation="'horizontal'" />
                  <ChartSeries>
                    <ChartSeriesItem v-for="(item, index) in campaignPerformanceBenchmarkData" :key="index"
                      :name="item?.name" :type="'column'" :data-items="item?.dataValue" />
                  </ChartSeries>
                </Chart>
              </div>
            </div>
            <div class="right-68 pl-46">
              <div>
                <p class="last-5-campaigns mb--1 py-7 px-12 font-16 l-height-24 base-color">
                  Last 5 Campaigns
                </p>
                <Grid :class="'performance-benchmark-table'" ref="grid" :data-items="campaignPerformanceBenchmarkData"
                  :columns="columnForPerformanceBenchmark">
                  <GridNoRecords> No Data found. </GridNoRecords>
                  <template v-slot:clickRateCell="{props}">
                    <td>
                      <div class="d-flex align-items-center">
                        <span class="flex-grow-1">{{props.dataItem?.clickRate}}%</span>
                      </div>
                    </td>
                  </template>
                  <template v-slot:openRateCell="{props}">
                    <td>
                      <div class="d-flex align-items-center">
                        <span class="flex-grow-1">{{props.dataItem?.openRate}}%</span>
                      </div>
                    </td>
                  </template>
                  <template v-slot:bounceRateCell="{props}">
                    <td>
                      <div class="d-flex align-items-center">
                        <span class="flex-grow-1">{{props.dataItem?.bouncedRate}}%</span>
                      </div>
                    </td>
                  </template>
                </Grid>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="pt-93 pb-122 text-center">
            <component is="performanceBenchmark"></component>
            <p class="max-w-220 max-w-220 mx-auto mb-0 mt-29 text-center text-color-2 font-16 l-height-24">
              No analytics were generated for this section yet.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>