<app-loader v-if="getEmailCampaignAnalyticsLoading"></app-loader>
<div v-if="!getEmailCampaignAnalyticsLoading" class="p-24">
  <div class="analytics-wrap">
    <div v-if="!getEmailCampaignAnalyticsLoading">
      <div class="analytics-card-all d-flex justify-content-between mb-32">
        <div class="analytics-card d-flex">
          <div class="icon-box">
            <span class="icon color-1">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.9981 5.33176C13.7343 5.96399 13.5433 6.63416 13.4355 7.3318L8.33333 7.33301C7.1091 7.33301 6.10508 8.27582 6.00774 9.47497L6 9.66634V18.6651L12 18.6663C12.5063 18.6663 12.9247 19.0425 12.9909 19.5306L13 19.6663C13 21.3232 14.3431 22.6663 16 22.6663C17.5878 22.6663 18.8875 21.4328 18.9931 19.8717L19 19.6663C19 19.1601 19.3762 18.7417 19.8643 18.6755L20 18.6663L26 18.6651L26.0014 16.356C26.736 15.9729 27.4089 15.4877 28.0011 14.9192L28 24.9997C28 27.3104 26.1914 29.1987 23.9126 29.3261L23.6667 29.333H8.33333C6.02262 29.333 4.13434 27.5244 4.00686 25.2456L4 24.9997V9.66634C4 7.35563 5.80861 5.46735 8.08743 5.33987L8.33333 5.33301L13.9981 5.33176ZM26 20.6651L20.9 20.6663C20.4539 22.8641 18.5663 24.5383 16.2671 24.6593L16 24.6663C13.581 24.6663 11.5633 22.9486 11.1 20.6663L6 20.6651V24.9997C6 26.2239 6.94281 27.2279 8.14196 27.3253L8.33333 27.333H23.6667C24.8909 27.333 25.8949 26.3902 25.9923 25.191L26 24.9997V20.6651ZM22 1.33301C26.0501 1.33301 29.3333 4.61625 29.3333 8.66634C29.3333 12.7164 26.0501 15.9997 22 15.9997C17.9499 15.9997 14.6667 12.7164 14.6667 8.66634C14.6667 4.61625 17.9499 1.33301 22 1.33301ZM22.2876 4.78446L22.1953 4.8616L22.1181 4.95393C21.9606 5.18128 21.9606 5.48474 22.1181 5.71208L22.1953 5.80441L24.3893 7.99967H17.3333L17.2135 8.01042C16.9414 8.05981 16.7268 8.27438 16.6774 8.54651L16.6667 8.66634L16.6774 8.78618C16.7268 9.0583 16.9414 9.27288 17.2135 9.32227L17.3333 9.33301H24.3893L22.1953 11.5283L22.1181 11.6206C21.9381 11.8804 21.9638 12.2397 22.1953 12.4711C22.4267 12.7025 22.7859 12.7282 23.0457 12.5482L23.1381 12.4711L26.5267 9.07434L26.5708 9.01094L26.6163 8.92106L26.6445 8.8372L26.6638 8.72806L26.6667 8.66634L26.6629 8.59514L26.6445 8.49549L26.6043 8.38448L26.5482 8.28679L26.4874 8.2115L23.1381 4.8616L23.0457 4.78446C22.8184 4.62697 22.5149 4.62697 22.2876 4.78446Z"
                  fill="#6F42C1"
                />
              </svg>
            </span>
          </div>
          <div class="content w-100">
            <p class="analytics-status">OPENED</p>
            <p class="info d-flex">
              <b
                >{{percentageCalc(getEmailCampaignAnalyticsData.uniqueOpened,getEmailCampaignAnalyticsData.uniqueDelivered)
                || 0}}%</b
              >{{getEmailCampaignAnalyticsData.uniqueOpened || 0 }} Unique Opens
              <span class="info-number ms-auto"
                >{{getEmailCampaignAnalyticsData.uniqueDelivered || 0}}
                Deliveries</span
              >
            </p>
            <ProgressBar
              :class="'color-1'"
              :value="percentageCalc(getEmailCampaignAnalyticsData.uniqueOpened,getEmailCampaignAnalyticsData.uniqueDelivered)"
            />
          </div>
        </div>
        <div class="analytics-card d-flex">
          <div class="icon-box">
            <span class="icon color-2">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.33325 4.6453C7.33325 2.98095 9.24753 2.04479 10.5612 3.06667L28.5803 17.0831C30.0833 18.2523 29.2566 20.6618 27.3523 20.6618H18.2158C17.5431 20.6618 16.9073 20.9695 16.4901 21.4972L10.9021 28.5642C9.72591 30.0516 7.33325 29.2199 7.33325 27.3237L7.33325 4.6453ZM27.3523 18.6618L9.33325 4.6453L9.33325 27.3237L14.9213 20.2567C15.7179 19.2493 16.9315 18.6618 18.2158 18.6618H27.3523Z"
                  fill="#0DCAF0"
                />
              </svg>
            </span>
          </div>
          <div class="content w-100">
            <p class="analytics-status">CLICKED</p>
            <p class="info d-flex">
              <b
                >{{percentageCalc(getEmailCampaignAnalyticsData.uniqueClicked,getEmailCampaignAnalyticsData.uniqueDelivered)
                || 0}}%</b
              >{{getEmailCampaignAnalyticsData.uniqueClicked || 0}} Unique
              Clicks
              <span class="info-number ms-auto"
                >{{getEmailCampaignAnalyticsData.uniqueDelivered || 0}}
                Deliveries</span
              >
            </p>
            <ProgressBar
              :class="'color-2'"
              :value="percentageCalc(getEmailCampaignAnalyticsData.uniqueClicked,getEmailCampaignAnalyticsData.uniqueDelivered)"
            />
          </div>
        </div>
        <div class="analytics-card d-flex">
          <div class="icon-box">
            <span class="icon color-3">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M25.3334 6C24.2288 6 23.3334 6.89543 23.3334 8C23.3334 9.10457 24.2288 10 25.3334 10C26.438 10 27.3334 9.10457 27.3334 8C27.3334 6.89543 26.438 6 25.3334 6ZM21.3334 8C21.3334 5.79086 23.1243 4 25.3334 4C27.5426 4 29.3334 5.79086 29.3334 8C29.3334 10.2091 27.5426 12 25.3334 12C25.1177 12 24.906 11.9829 24.6995 11.9501L21.982 16.4279C22.4143 17.0668 22.6667 17.8372 22.6667 18.6667C22.6667 20.8758 20.8759 22.6667 18.6667 22.6667C17.4629 22.6667 16.3833 22.1349 15.65 21.2934L10.6612 23.7874C10.6649 23.8578 10.6667 23.9287 10.6667 24C10.6667 26.2091 8.87589 28 6.66675 28C4.45761 28 2.66675 26.2091 2.66675 24C2.66675 21.7909 4.45761 20 6.66675 20C8.0883 20 9.33665 20.7415 10.0461 21.8589L14.7547 19.505C14.6971 19.2346 14.6667 18.9542 14.6667 18.6667C14.6667 16.4575 16.4576 14.6667 18.6667 14.6667C19.3098 14.6667 19.9174 14.8184 20.4557 15.088L22.8528 11.1381C21.9272 10.4055 21.3334 9.27209 21.3334 8ZM18.6667 16.6667C17.5622 16.6667 16.6667 17.5621 16.6667 18.6667C16.6667 19.7712 17.5622 20.6667 18.6667 20.6667C19.7713 20.6667 20.6667 19.7712 20.6667 18.6667C20.6667 17.5621 19.7713 16.6667 18.6667 16.6667ZM6.66675 22C5.56218 22 4.66675 22.8954 4.66675 24C4.66675 25.1046 5.56218 26 6.66675 26C7.77132 26 8.66675 25.1046 8.66675 24C8.66675 22.8954 7.77132 22 6.66675 22Z"
                  fill="#DC3545"
                />
              </svg>
            </span>
          </div>
          <div class="content w-100">
            <p class="analytics-status">Bounced</p>
            <p class="info d-flex">
              <b
                >{{percentageCalc(getEmailCampaignAnalyticsData.uniqueBounced,getEmailCampaignAnalyticsData.uniqueSent)
                || 0}}%</b
              >{{getEmailCampaignAnalyticsData.uniqueBounced || 0}} Bounces
              <span class="info-number ms-auto"
                >{{getEmailCampaignAnalyticsData.totalMember || 0}}
                Recipients</span
              >
            </p>
            <ProgressBar
              :class="'color-3'"
              :value="percentageCalc(getEmailCampaignAnalyticsData.totalBounced,getEmailCampaignAnalyticsData.uniqueSent)"
            />
          </div>
        </div>
        <div class="analytics-card d-flex">
          <div class="icon-box">
            <span class="icon color-4">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.66552 4C9.3217 4 10.6643 5.3426 10.6643 6.99878V25.0012C10.6643 26.6574 9.3217 28 7.66552 28C6.00935 28 4.66675 26.6574 4.66675 25.0012V6.99878C4.66675 5.3426 6.00935 4 7.66552 4ZM16.3322 9.33333C17.9884 9.33333 19.331 10.6759 19.331 12.3321V25.0012C19.331 26.6574 17.9884 28 16.3322 28C14.676 28 13.3334 26.6574 13.3334 25.0012V12.3321C13.3334 10.6759 14.676 9.33333 16.3322 9.33333ZM24.9989 14.6667C26.655 14.6667 27.9976 16.0093 27.9976 17.6654V25.0012C27.9976 26.6574 26.655 28 24.9989 28C23.3427 28 22.0001 26.6574 22.0001 25.0012V17.6654C22.0001 16.0093 23.3427 14.6667 24.9989 14.6667ZM7.66553 6C7.11392 6 6.66675 6.44717 6.66675 6.99878V25.0012C6.66675 25.5528 7.11392 26 7.66552 26C8.21713 26 8.6643 25.5528 8.6643 25.0012V6.99878C8.6643 6.44717 8.21713 6 7.66553 6ZM16.3322 11.3333C15.7806 11.3333 15.3334 11.7805 15.3334 12.3321V25.0012C15.3334 25.5528 15.7806 26 16.3322 26C16.8838 26 17.331 25.5528 17.331 25.0012V12.3321C17.331 11.7805 16.8838 11.3333 16.3322 11.3333ZM24.9989 16.6667C24.4472 16.6667 24.0001 17.1138 24.0001 17.6654V25.0012C24.0001 25.5528 24.4472 26 24.9989 26C25.5505 26 25.9976 25.5528 25.9976 25.0012V17.6654C25.9976 17.1138 25.5505 16.6667 24.9989 16.6667Z"
                  fill="#2990FF"
                />
              </svg>
            </span>
          </div>
          <div class="content w-100">
            <p class="analytics-status">TOTAL CONVERSIONS</p>
            <p class="info d-flex">
              <b
                >{{percentageCalc(getEmailCampaignAnalyticsData.totalConversion,getEmailCampaignAnalyticsData.uniqueDelivered)
                }}% </b
              >{{getEmailCampaignAnalyticsData.totalConversion || 0}}
              Conversions
              <span class="info-number ms-auto"
                >{{getEmailCampaignAnalyticsData.uniqueDelivered || 0}}
                Deliveries</span
              >
            </p>
            <ProgressBar
              :class="'color-4'"
              :value="percentageCalc(getEmailCampaignAnalyticsData.totalConversion,getEmailCampaignAnalyticsData.uniqueDelivered)"
            />
          </div>
        </div>
      </div>
      <appLineChart
        :dataAnalytics="getEmailCampaignAnalyticsData"
        :loader="getEmailCampaignAnalyticsLoading"
      >
      </appLineChart>
    </div>
  </div>
  <div class="mt-34">
    <div
      class="d-flex flex-wrap flex-sm-wrap flex-md-nowrap justify-content-between"
    >
      <div class="w-50-p w-100-sm mr-15 mr-0-md mb-3 mb-md-0">
        <div class="div-base-table">
          <div class="dbt-row">
            <div>Total Sent</div>
            <div class="last">
              {{getEmailCampaignAnalyticsData?.uniqueSent || 0 }}
            </div>
          </div>
          <div class="dbt-row">
            <div>Successful Deliveries</div>
            <div class="last">
              {{getEmailCampaignAnalyticsData?.uniqueDelivered || 0}}
            </div>
          </div>
          <div class="dbt-row">
            <div>Total Opens</div>
            <div class="last">
              {{getEmailCampaignAnalyticsData?.totalOpened || 0}}
            </div>
          </div>
          <div class="dbt-row">
            <div>Total Clicks</div>
            <div class="last">
              {{getEmailCampaignAnalyticsData?.totalClicked || 0}}
            </div>
          </div>
        </div>
      </div>
      <div class="w-50-p w-100-sm ml-15 ml-0-md">
        <div class="div-base-table">
          <div class="dbt-row">
            <div>Total Conversions</div>
            <div class="last">
              {{getEmailCampaignAnalyticsData.totalConversion || 0}}
            </div>
          </div>
          <div class="dbt-row">
            <div>Conversion value</div>
            <div class="last">
              {{getEmailCampaignAnalyticsData.totalConversionValue || 0}} AUD
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
