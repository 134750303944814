<template>
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="60" height="60" rx="30" fill="#FFC107" fill-opacity="0.08"/>
    <path d="M27.9981 19.3318C27.7343 19.964 27.5433 20.6342 27.4355 21.3318L22.3333 21.333C21.1091 21.333 20.1051 22.2758 20.0077 23.475L20 23.6663V32.6651L26 32.6663C26.5063 32.6663 26.9247 33.0425 26.9909 33.5306L27 33.6663C27 35.3232 28.3431 36.6663 30 36.6663C31.5878 36.6663 32.8875 35.4328 32.9931 33.8717L33 33.6663C33 33.1601 33.3762 32.7417 33.8643 32.6755L34 32.6663L40 32.6651L40.0014 30.356C40.736 29.9729 41.4089 29.4877 42.0011 28.9192L42 38.9997C42 41.3104 40.1914 43.1987 37.9126 43.3261L37.6667 43.333H22.3333C20.0226 43.333 18.1343 41.5244 18.0069 39.2456L18 38.9997V23.6663C18 21.3556 19.8086 19.4673 22.0874 19.3399L22.3333 19.333L27.9981 19.3318ZM40 34.6651L34.9 34.6663C34.4539 36.8641 32.5663 38.5383 30.2671 38.6593L30 38.6663C27.581 38.6663 25.5633 36.9486 25.1 34.6663L20 34.6651V38.9997C20 40.2239 20.9428 41.2279 22.142 41.3253L22.3333 41.333H37.6667C38.8909 41.333 39.8949 40.3902 39.9923 39.191L40 38.9997V34.6651ZM36 15.333C40.0501 15.333 43.3333 18.6163 43.3333 22.6663C43.3333 26.7164 40.0501 29.9997 36 29.9997C31.9499 29.9997 28.6667 26.7164 28.6667 22.6663C28.6667 18.6163 31.9499 15.333 36 15.333ZM36.2876 18.7845L36.1953 18.8616L36.1181 18.9539C35.9606 19.1813 35.9606 19.4847 36.1181 19.7121L36.1953 19.8044L38.3893 21.9997H31.3333L31.2135 22.0104C30.9414 22.0598 30.7268 22.2744 30.6774 22.5465L30.6667 22.6663L30.6774 22.7862C30.7268 23.0583 30.9414 23.2729 31.2135 23.3223L31.3333 23.333H38.3893L36.1953 25.5283L36.1181 25.6206C35.9381 25.8804 35.9638 26.2397 36.1953 26.4711C36.4267 26.7025 36.7859 26.7282 37.0457 26.5482L37.1381 26.4711L40.5267 23.0743L40.5708 23.0109L40.6163 22.9211L40.6445 22.8372L40.6638 22.7281L40.6667 22.6663L40.6629 22.5951L40.6445 22.4955L40.6043 22.3845L40.5482 22.2868L40.4874 22.2115L37.1381 18.8616L37.0457 18.7845C36.8184 18.627 36.5149 18.627 36.2876 18.7845Z" fill="#FFC107"/>
    </svg>
</template>
<script>
    export default {
        name: "testIcon"
    }
</script>
<style scoped></style>