<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_8768_42029)">
    <path d="M3 1.05952C3 0.214445 3.98325 -0.249631 4.63564 0.28753L15.6418 9.3497C16.3661 9.94607 15.9444 11.1217 15.0061 11.1217H9.41424C8.97879 11.1217 8.56483 11.3109 8.27991 11.6402L4.75622 15.7127C4.15025 16.413 3 15.9844 3 15.0583L3 1.05952ZM15.0061 10.1217L4 1.05952L4 15.0583L7.52369 10.9859C7.99856 10.4371 8.68849 10.1217 9.41423 10.1217H15.0061Z" fill="#86A2FE"/>
    </g>
    <defs>
    <clipPath id="clip0_8768_42029">
    <rect width="16" height="16" fill="white"/>
    </clipPath>
    </defs>
    </svg>
</template>
<script>
    export default {
        name: "clickedIcon"
    }
</script>
<style scoped></style>