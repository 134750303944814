<template src="./emailCampaignAdvanceReport.html" lang=""></template>
<style lang="scss">
@import "./emailCampaignAdvanceReport.scss";
</style>

<script>
import {
  Chart,
  ChartTitle,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartTooltip,
  ChartSeriesItemTooltip,
  ChartLegend,
} from "@progress/kendo-vue-charts";
import appLineChart from "../../../../common/AppLineChart/appLineChart.vue";
import AppLoader from "@/components/common/AppLoader/AppLoader.vue";
import { ProgressBar } from "@progress/kendo-vue-progressbars";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { Button } from "@progress/kendo-vue-buttons";
import NoDataRender from "@/components/common/NoDataRender/NoDataRender.vue";
import { filterBy } from "@progress/kendo-data-query";
import "hammerjs";
import { DatePicker } from "@progress/kendo-vue-dateinputs";
import { Grid, GridToolbar, GridNoRecords } from "@progress/kendo-vue-grid";
import clientsAndDevices from "@/components/svg-icons/clientsAndDevices.vue";
import performanceByLocation from "@/components/svg-icons/performanceByLocation.vue";
import performanceBenchmarksEmpty from "@/components/svg-icons/performanceBenchmarksEmpty.vue";

export default {
  name: "emailCampaignAdvanceReport",
  components: {
    Grid: Grid,
    toolbar: GridToolbar,
    GridNoRecords,
    Chart,
    ChartTitle,
    ChartSeries,
    ChartSeriesItem,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartTooltip,
    ChartSeriesItemTooltip,
    ChartLegend,
    appLineChart,
    AppLoader,
    ProgressBar,
    dropdownlist: DropDownList,
    kbutton: Button,
    custom: NoDataRender,
    datePicker: DatePicker,
    clientsAndDevices,
    performanceByLocation,
    performanceBenchmarksEmpty,
  },
  data: function () {
    return {
      deviceFilterObject: {
        clientAndDevices: [
          {
            name: "Overall",
            value: "overall",
            checked: true,
            id: 1,
          },
          {
            name: "Mobile",
            value: "mobile",
            checked: false,
            id: 2,
          },
          {
            name: "Desktop",
            value: "desktop",
            checked: false,
            id: 3,
          },
        ],
        performanceBenchmarks: [
          {
            name: "Click Rate",
            value: "clickRate",
            checked: true,
            id: 1,
          },
          {
            name: "Open Rate",
            value: "openRate",
            checked: false,
            id: 2,
          },
          {
            name: "Converted",
            value: "converted",
            checked: false,
            id: 3,
          },
        ],
        performanceByVariations: [
          {
            name: "Overall",
            value: "overall",
            checked: true,
            id: 1,
          },
          {
            name: "Mobile",
            value: "mobile",
            checked: false,
            id: 2,
          },
          {
            name: "Desktop",
            value: "desktop",
            checked: false,
            id: 3,
          },
        ],
      },
      currentTimeZone: null,
      listNoDataRender: "noDataFoundTemplate",
      overallPerformanceHtmlTreeObject: [
        {
          title: "SENT",
          color: "color-1",
          id: 1,
        },
        {
          title: "UNIQUE OPENS",
          color: "color-2",
          id: 2,
        },
        {
          title: "UNIQUE CLICKS",
          color: "color-4",
          id: 3,
        },
        {
          title: "CONVERTED",
          color: "color-3",
          id: 4,
        },
        {
          title: "AVG. VALUE",
          color: "color-3",
          id: 5,
        },
        {
          title: "BOUNCED",
          color: "color-5",
          id: 6,
        },
        {
          title: "TOTAL OPENS",
          color: "color-2",
          id: 7,
        },
        {
          title: "TOTAL CLICKS",
          color: "color-4",
          id: 8,
        },
        {
          title: "TOTAL VALUE",
          color: "color-3",
          id: 9,
        },
        {
          title: "VALUE/SEND",
          color: "color-3",
          id: 10,
        },
      ],
      performanceOverTimeHtmlTreeObject: [
        {
          title: "SENT",
          color: "color-1",
          id: 1,
        },
        {
          title: "UNIQUE OPENS",
          color: "color-2",
          id: 2,
        },
        {
          title: "UNIQUE CLICKS",
          color: "color-4",
          id: 3,
        },
        {
          title: "TOTAL VALUE",
          color: "color-3",
          id: 4,
        },
        {
          title: "BOUNCED",
          color: "color-5",
          id: 5,
        },
      ],
      dropdownListData: [
        {
          id: 1,
          text: "Last 24 Hours",
          message: "Showing performance for last 24 hours",
        },
        {
          id: 2,
          text: "Last 7 days",
          message: "Showing performance for last 7 days",
        },
        {
          id: 3,
          text: "Last 1 Month",
          message: "Showing performance for last 1 month",
        },
        {
          id: 4,
          text: "Last 3 Months",
          message: "Showing performance for last 3 months",
        },
        {
          id: 5,
          text: "Custom Date Range",
          message: "Showing performance for custom date range",
        },
      ],
      selectedDropdownvalue: {
        id: 1,
        text: "Last 24 Hours",
        message: "Showing performance for last 24 hours",
      },
      selectedVariation: { id: null, variationNo: "All Variation" },
      variationsData: [],

      startDate: null,
      endDate: null,
    };
  },

  props: {
    campaignDetails: {
      type: Object,
      default: () => {},
    },
  },

  mounted() {
    this.currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const currentDate = new Date();
    const startDate = this.subtractDays(new Date(), 1);

    const payload = {
      campaignId: this.$route.params.campaignId,
      clubId: this.$route.params.id,
    };
    this.$store.dispatch("getCampaignVariations", payload);
    this.$store.dispatch("getCampaignClientDevices", payload);
    this.$store.dispatch("getCampaignPerformanceByLocation", payload);
    this.$store.dispatch("getCampaignPerformanceBenchmark", payload);
    this.$store.dispatch("getCampaignOverallPerformance", payload);
    this.$store.dispatch("getCampaignPerformanceOverTime", {
      campaignId: this.$route.params.campaignId,
      fromDate: startDate,
      toDate: currentDate,
      zone: this.currentTimeZone,
      clubId: this.$route.params.id,
    });
  },

  computed: {
    columnForClientAndDevicesTypeMobile() {
      return [
        {
          field: "category",
          title: `Mobile (${Math.floor(
            this.campaignClientDevicesData?.totalMobile
          )})%`,
          cell: "categoryCell",
        },
        {
          field: "percentage",
          title: "%(Percentage)",
          cell: "percentageCell",
        },
      ];
    },
    columnForClientAndDevicesTypeDesktop() {
      return [
        {
          field: "category",
          title: `Desktop (${Math.floor(
            this.campaignClientDevicesData?.totalDesktop
          )})%`,
          cell: "categoryCell",
        },
        {
          field: "percentage",
          title: "%(Percentage)",
          cell: "percentageCell",
        },
      ];
    },
    columnForPerformanceByLocations() {
      return [
        {
          field: "category",
          title: "Countries",
          cell: "categoryCell",
        },
        {
          field: "percentage",
          title: "%(Percentage)",
          cell: "percentageCell",
        },
      ];
    },
    columnForPerformanceBenchmark() {
      return [
        {
          field: "name",
          title: "Campaign Name",
        },
        {
          field: "TotalAudience",
          title: "Audience",
        },
        {
          field: "clickRate",
          title: "Click Rate",
          cell: "clickRateCell",
        },
        {
          field: "openRate",
          title: "Open Rate",
          cell: "openRateCell",
        },
        {
          field: "bounceRate",
          title: "Bounce",
          cell: "bounceRateCell",
        },
      ];
    },
    getEmailCampaignAnalyticsData() {
      return this.$store.state.emailCampaignAnalytics
        .emailCampaignAnalyticsData;
    },
    getEmailCampaignAnalyticsLoading() {
      return this.$store.state.emailCampaignAnalytics
        .emailCampaignAnalyticsLoader;
    },
    campaignVariationsData() {
      return this.$store.state.emailCampaignAdvanceReport
        .campaignVariationsData;
    },
    campaignOverallPerformanceData() {
      return this.$store.state.emailCampaignAdvanceReport
        .campaignOverallPerformanceData;
    },
    campaignOverallPerformanceLoader() {
      return this.$store.state.emailCampaignAdvanceReport
        .campaignOverallPerformanceLoader;
    },
    campaignPerformanceOverTimeData() {
      return this.$store.state.emailCampaignAdvanceReport
        .campaignPerformanceOverTimeData;
    },
    campaignPerformanceOverTimeLoader() {
      return this.$store.state.emailCampaignAdvanceReport
        .campaignPerformanceOverTimeLoader;
    },
    campaignClientDevicesData() {
      return this.$store.state.emailCampaignAdvanceReport
        .campaignClientDevicesData;
    },
    campaignClientDevicesLoader() {
      return this.$store.state.emailCampaignAdvanceReport
        .campaignClientDevicesLoader;
    },
    campaignPerformanceByLocationData() {
      return this.$store.state.emailCampaignAdvanceReport
        .campaignPerformanceByLocationData;
    },
    campaignPerformanceByLocationLoader() {
      return this.$store.state.emailCampaignAdvanceReport
        .campaignPerformanceByLocationLoader;
    },
    campaignPerformanceBenchmarkData() {
      return this.$store.state.emailCampaignAdvanceReport
        .campaignPerformanceBenchmarkData;
    },
    campaignPerformanceBenchmarkLoader() {
      return this.$store.state.emailCampaignAdvanceReport
        .campaignPerformanceBenchmarkLoader;
    },
  },
  methods: {
    valuePerSendCalc(value1, value2) {
      if (!value1 || !value2) return 0;
      return (value1 / value2).toFixed(2);
    },
    toggleFilterDeviceHandler(type, index) {
      for (let i = 0; i < this.deviceFilterObject[type].length; i++) {
        this.deviceFilterObject[type][i].checked = false;
      }
      this.deviceFilterObject[type][index].checked = true;

      if (type == "clientAndDevices") {
        this.campaignClientDevicesData.selected =
          this.campaignClientDevicesData[
            this.deviceFilterObject[type][index]?.value
          ];
      }

      if (type == "performanceBenchmarks") {
        this.campaignPerformanceBenchmarkData.map((item) => {
          item.dataValue = [item[this.deviceFilterObject[type][index]?.value]];
        });
      }
    },
    updateStartDate(event) {
      const selectedStartDate = new Date(event.target.value);
      if (this.endDate && selectedStartDate > new Date(this.endDate)) {
        this.$store.commit("SHOW_NOTIFICATION", {
            message: "Start day can't be bigger than end day.",
            type: "warning",
          });
        return;
      }
      this.startDate = selectedStartDate;
      this.reFetchPerformanceOverTimeData();
    },
    updateEndDate(event) {
      const selectedEndDate = new Date(event.target.value);
      if (!this.startDate) {
        this.$store.commit("SHOW_NOTIFICATION", {
          message: "Please select a start day first.",
          type: "warning",
        });
        return;
      }

      if (selectedEndDate < new Date(this.startDate)) {
        this.$store.commit("SHOW_NOTIFICATION", {
          message: "End day can't be before start day.",
          type: "warning",
        });
        return;
      }
      this.endDate = selectedEndDate;
      this.reFetchPerformanceOverTimeData();
    },
    percentageCalculator(value, total) {
      if (!value || !total) return 0;
      return parseFloat(((value / total) * 100).toFixed(2));
    },
    subTractMinutes(date, minutes) {
      return new Date(date.getTime() - minutes * 60000);
    },
    subtractDays(date, days) {
      let result = new Date(date);
      result.setDate(result.getDate() - days);
      return result;
    },
    subtractMonths(date, months) {
      let result = new Date(date);
      result.setMonth(result.getMonth() - months);
      return result;
    },
    reFetchPerformanceOverTimeData() {
      let fromDate = null;
      let dateObject;
      let toDate = new Date();

      if (this.selectedDropdownvalue?.id == 1) {
        fromDate = this.subtractDays(new Date(), 1);
      }

      if (this.selectedDropdownvalue?.id == 2) {
        fromDate = this.subtractDays(new Date(), 7);
      }

      if (this.selectedDropdownvalue?.id == 3) {
        fromDate = this.subtractMonths(new Date(), 1);
      }

      if (this.selectedDropdownvalue?.id == 4) {
        fromDate = this.subtractMonths(new Date(), 3);
      }

      if (this.selectedDropdownvalue?.id == 5) {
        if (this.startDate && this.endDate) {
          // add current utc time to from date and to date.
          fromDate = this.startDate;
          toDate = this.endDate;

          if (this.endDate.toDateString() == new Date().toDateString()) {
            let result = new Date(this.endDate);
            result.setDate(result.getDate() + 1);
            toDate = this.subTractMinutes(result, 1);
          }
        } else {
          return;
        }
      }
      this.$store.dispatch("getCampaignPerformanceOverTime", {
        campaignId: this.$route.params.campaignId,
        campaignCombinationId: this.selectedVariation.id,
        fromDate: fromDate,
        toDate: toDate,
        zone: this.currentTimeZone,
        clubId: this.$route.params.id,
      });
    },
    onVariationDropdownOpen() {
      this.variationsData = structuredClone(this.campaignVariationsData);
    },
    variationFilterChange(event) {
      this.variationsData = this.variationFilterData(event.filter);
    },
    variationFilterData(filter) {
      const data = this.campaignVariationsData.slice();
      return filterBy(data, filter);
    },
    handledropdownSelection(event) {
      this.selectedDropdownvalue = event.value;
      if (this.selectedDropdownvalue.id == 5) {
        this.startDate = null;
        this.endDate = null;
      }
      this.reFetchPerformanceOverTimeData();
    },
    handleVariationChange(event) {
      this.selectedVariation = event.value;
      this.reFetchPerformanceOverTimeData();
    },
    percentageCalc(a, b) {
      return Math.ceil((a / b) * 100);
    },
  },
};
</script>
