<template>
  <AppSnackBar :message="notifyMessage" :notificationType="notifyType" v-if="isNotify"></AppSnackBar>

  <app-loader v-if="loading || actionLoadingStatus"></app-loader>

  <div v-if="recipientActivityData?.length != 0 ||
    emailStatusFilterId != 0 ||
    searchText
    " class="dashboard-right-inner">
    <div class="p-24 bg-white">
      <Grid ref="grid" :class="'max-height-100-251 recipient-activity-table-wrap sorting-btn-th'"
        :data-items="recipientActivityData" :take="take" :skip="skip" :total="total" :pageable="gridPageable"
        :columns="columns" @datastatechange="dataStateChange" :sortable="true" :sort="sort"
        @sortchange="sortChangeHandler">
        <template v-slot:email="{ props }">
          <td>
            <div class="d-flex">
              {{ isNameLimit(props?.dataItem?.email) }}
            </div>
          </td>
        </template>

        <template v-slot:name="{ props }">
          <td>
            <div class="d-flex">
              {{ isNameLimit(props?.dataItem?.name) }}
            </div>
          </td>
        </template>

        <template v-slot:currentStatusCell="{ props }">
          <td>
            <span v-if="props.dataItem.currentStatus == 'Not Sent'">
              <component :is="'notSentIcon'"></component>
            </span>
            <span v-if="props.dataItem.currentStatus == 'Sending'">
              <component :is="'scheduledIcon'"></component>
            </span>
            <span v-if="props.dataItem.currentStatus == 'Sent'">
              <component :is="'sentIcon'"></component>
            </span>
            <span v-if="props.dataItem.currentStatus == 'Opened'">
              <component :is="'openedIcon'"></component>
            </span>
            <span v-if="props.dataItem.currentStatus == 'Clicked'">
              <component :is="'clickedIcon'"></component>
            </span>
            <span v-if="props.dataItem.currentStatus == 'Delivered'">
              <component :is="'deliveredIcon'"></component>
            </span>
            <span v-if="props.dataItem.currentStatus == 'Not Delivered'">
              <component :is="'notSentIcon'"></component>
            </span>
            <span v-if="props.dataItem.currentStatus == 'Scheduled'">
              <component :is="'scheduledIcon'"></component>
            </span>
            <span v-if="props.dataItem.currentStatus == 'Bounced'">
              <component :is="'bouncedIcon'"></component>
            </span>
            <span class="ps-2">{{ props.dataItem.currentStatus }}</span>
          </td>
        </template>

        <template v-slot:actionCell="{ props }">
          <td>
            <div class="d-flex">
              <kbutton
                :disabled="props.dataItem.currentStatus == 'Scheduled' || [4, 5]?.includes(campaignDetails?.campaignStatus) || this.hasCreateAccess() == false"
                :fill-mode="'flat'" :theme-color="'primary'" @click="resendEmailHandler(props.dataItem)"
                :class="'p-0 hover-none-btn'">
                Resend</kbutton>
            </div>
          </td>
        </template>

        <toolbar>
          <div class="search-box-wrap width-220 w-100-vsm me-3 mr-0-vsm">
            <span class="k-icon k-i-zoom"></span>
            <span class="k-textbox k-grid-search k-display-flex">
              <k-input :class="'mb-0 width-220 w-100-vsm'" placeholder="Search" :value="searchWord"
                @input="onSearch"></k-input>
            </span>
          </div>

          <div class="pe-3 pr-0-vsm flex-vsm-wrap w-100-vsm">
            <label class="pe-2 mb-0 w-100-vsm">Email Status</label>
            <DropDownList :class="'w-100-vsm'" :style="{ width: '160px' }" :data-items="emailStatuses"
              :text-field="'text'" :data-item-key="'id'" :value="emailStatusValue" @change="handleEmailStatusValue">
            </DropDownList>
          </div>

          <div class="ms-auto ml-unset-767 w-100-vsm">
            <div class="ms-auto ml-unset-767 w-100-vsm">
              <div class="d-flex w-100-vsm">
                <kbutton :class="'w-100-vsm'" @click="exportReport">Export</kbutton>
              </div>
            </div>
          </div>
        </toolbar>
      </Grid>
    </div>
  </div>

  <div v-if="recipientActivityData?.length == 0 &&
    !searchText &&
    emailStatusFilterId == 0 &&
    !loading
    ">
    <div
      class="dashboard-content bg-white radius-5 min-height-calc-100-182 d-flex justify-content-center align-items-center">
      <div class="width-747 text-center">
        <svg width="216" height="185" viewBox="0 0 216 185" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle opacity="0.05" cx="108" cy="92.5" r="92" fill="#253858" />
          <g filter="url(#filter0_bdd_6009_153059)">
            <rect x="31.7812" y="53.5176" width="154.563" height="98.2819" rx="1.93432" fill="white" fill-opacity="0.13"
              shape-rendering="crispEdges" />
          </g>
          <g filter="url(#filter1_bdd_6009_153059)">
            <rect x="28.0996" y="44.4238" width="161.928" height="102.965" rx="1.88497" fill="white" fill-opacity="0.13"
              shape-rendering="crispEdges" />
          </g>
          <g filter="url(#filter2_dd_6009_153059)">
            <rect x="23" y="33.5" width="170" height="108" rx="3.69388" fill="white" />
          </g>
          <rect x="37.25" y="47.75" width="141.5" height="13.5" rx="2.75" fill="url(#paint0_linear_6009_153059)"
            stroke="#0052CC" stroke-width="0.5" />
          <rect x="37.25" y="69.75" width="141.5" height="13.5" rx="2.75" fill="#F3F3F3" stroke="#C7CBDB"
            stroke-width="0.5" />
          <rect x="37.25" y="91.75" width="141.5" height="13.5" rx="2.75" fill="#F3F3F3" stroke="#C7CBDB"
            stroke-width="0.5" />
          <rect x="37.25" y="113.75" width="116.5" height="13.5" rx="2.75" fill="#F3F3F3" stroke="#C7CBDB"
            stroke-width="0.5" />
          <circle cx="173" cy="121.5" r="10.75" fill="#F6F9FF" stroke="#8C95B6" stroke-width="0.5"
            stroke-dasharray="2 1" />
          <path
            d="M173.556 120.934H176.976V122.08H173.556V125.616H172.402V122.08H168.999V120.934H172.402V117.381H173.556V120.934Z"
            fill="#8C95B6" />
          <defs>
            <filter id="filter0_bdd_6009_153059" x="23.7421" y="45.4784" width="170.641" height="114.361"
              filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImageFix" stdDeviation="4.01957" />
              <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_6009_153059" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha" />
              <feOffset dy="1.81524" />
              <feGaussianBlur stdDeviation="1.81524" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0" />
              <feBlend mode="normal" in2="effect1_backgroundBlur_6009_153059" result="effect2_dropShadow_6009_153059" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha" />
              <feOffset dy="4.01957" />
              <feGaussianBlur stdDeviation="2.00978" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0" />
              <feBlend mode="normal" in2="effect2_dropShadow_6009_153059" result="effect3_dropShadow_6009_153059" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_6009_153059" result="shape" />
            </filter>
            <filter id="filter1_bdd_6009_153059" x="20.3047" y="36.6289" width="177.518" height="118.556"
              filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImageFix" stdDeviation="3.89744" />
              <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_6009_153059" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha" />
              <feOffset dy="1.76009" />
              <feGaussianBlur stdDeviation="1.76009" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
              <feBlend mode="normal" in2="effect1_backgroundBlur_6009_153059" result="effect2_dropShadow_6009_153059" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha" />
              <feOffset dy="3.89744" />
              <feGaussianBlur stdDeviation="1.94872" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0" />
              <feBlend mode="normal" in2="effect2_dropShadow_6009_153059" result="effect3_dropShadow_6009_153059" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_6009_153059" result="shape" />
            </filter>
            <filter id="filter2_dd_6009_153059" x="0.8367" y="18.7245" width="214.327" height="152.327"
              filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha" />
              <feOffset dy="7.38777" />
              <feGaussianBlur stdDeviation="11.0816" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0.04 0 0 0 0 0.168627 0 0 0 0 0.36 0 0 0 0.08 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6009_153059" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha" />
              <feOffset dy="1.84694" />
              <feGaussianBlur stdDeviation="1.84694" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
              <feBlend mode="normal" in2="effect1_dropShadow_6009_153059" result="effect2_dropShadow_6009_153059" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_6009_153059" result="shape" />
            </filter>
            <linearGradient id="paint0_linear_6009_153059" x1="73" y1="44.3889" x2="177.507" y2="50.5259"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#D3DFF6" />
              <stop offset="1" stop-color="#C2D5FD" stop-opacity="0" />
            </linearGradient>
          </defs>
        </svg>
        <p class="color-light2 mt-3 mb-5">
          No recipients have entered your selected <br />segment or list.
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "./emailCampaignRecipientActivity.scss";
</style>

<script>
import {
  getEmailCampaignRecipientActivityService,
  resendCampaignService,
} from "@/services/clubDetails/emailCampaign.service";
import { Grid, GridToolbar } from "@progress/kendo-vue-grid";
import { Input } from "@progress/kendo-vue-inputs";
import { Button } from "@progress/kendo-vue-buttons";
import AppSnackBar from "../../../../common/AppSnackBar/AppSnackBar.vue";
import AppLoader from "@/components/common/AppLoader/AppLoader.vue";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { configuration } from "@/configurationProvider";
import { auth } from "@/services/auth.service";
import axios from "axios";
import clickedIcon from "../../../../svg-icons/clickedIcon.vue";
import notSentIcon from "../../../../svg-icons/notSentIcon.vue";
import bouncedIcon from "../../../../svg-icons/bouncedIcon.vue";
import openedIcon from "../../../../svg-icons/openedIcon.vue";
import sentIcon from "../../../../svg-icons/sentIcon.vue";
import scheduledIcon from "../../../../svg-icons/scheduledIcon.vue";
import deliveredIcon from "../../../../svg-icons/deliveredIcon.vue";
export default {
  name: "emailCampaignRecipientActivity",
  components: {
    Grid,
    toolbar: GridToolbar,
    "k-input": Input,
    kbutton: Button,
    AppSnackBar,
    "app-loader": AppLoader,
    DropDownList,
    clickedIcon,
    notSentIcon,
    bouncedIcon,
    openedIcon,
    sentIcon,
    scheduledIcon,
    deliveredIcon,
  },
  data: function () {
    return {
      loading: false,
      actionLoadingStatus: false,
      recipientActivityData: [],
      campaignId: this.$route.params.campaignId,

      searchWord: "",
      searchText: "",
      gridPageable: {
        buttonCount: 5,
        info: true,
        type: "numeric",
        pageSizes: [10, 50, 100, 200, 500, 1000],
        previousNext: true,
      },

      total: 0,
      skip: 0,
      take: 50,
      timer: null,

      notifyMessage: "",
      notifyType: "",
      isNotify: false,

      sort: [{ field: "email", dir: "asc" }],

      emailStatuses: [
        { id: 0, text: "All", value: "All" },
        { id: 1, text: "Not Sent", value: "NotSent" },
        { id: 2, text: "Sending", value: "Sending" },
        { id: 3, text: "Sent", value: "Sent" },
        { id: 4, text: "Opened", value: "Opened" },
        { id: 5, text: "Clicked", value: "Clicked" },
        { id: 6, text: "Delivered", value: "Delivered" },
        { id: 7, text: "Bounced", value: "NotDelivered" },
        { id: 8, text: "Scheduled", value: "Scheduled" },
      ],
      emailStatusValue: { id: 0, text: "All", value: "All" },
      emailStatusFilterId: 0,
    };
  },
  props: {
    campaignDetails: {
      type: Object,
      default: () => { },
    }
  },
  computed: {
    columns() {
      return [
        {
          field: "email",
          title: "Email",
          cell: "email",
        },
        {
          field: "name",
          title: "Name",
          cell: "name",
        },
        {
          field: "totalOpened",
          title: "Opens",
        },
        {
          field: "totalClicked",
          title: "Clicks",
        },
        {
          field: "currentStatus",
          title: "Email Status",
          cell: "currentStatusCell",
        },
        {
          field: "action",
          title: "Action",
          cell: "actionCell",
        },
      ];
    },
  },
  mounted() {
    const payload = {
      campaignId: this.campaignId,
      limit: this.take,
      offset: this.skip,
    };
    this.getEmailCampaignRecipientActivity(payload);
  },

  methods: {
    hasCreateAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasPostPermission",
        moduleCode: "emailCampaign",
        itemCode: 'allCampaign',
        childItemCode: null,
      });
    },
    sortChangeHandler: function (e) {
      this.sort = e.sort;
      let sortBy = "";
      let sortDir = "";

      if (e.sort.length > 0) {
        sortBy = e.sort[0].field;
        sortDir = e.sort[0].dir;
      } else {
        sortBy = "";
        sortDir = "";
      }

      ;

      let payload = {
        campaignId: this.campaignId,
        limit: this.take,
        offset: this.skip,
        searchQuery: this.searchWord,
        ...(this.emailStatusValue.id != 0 && {
          campaignEmailStatus: this.emailStatusValue.id,
        }),
      };

      if (sortBy && sortDir) {
        payload.orderBy = `${sortBy} ${sortDir}`;
      }

      this.getEmailCampaignRecipientActivity(payload);
    },
    getEmailCampaignRecipientActivity(payload, type) {
      this.loading = true;
      payload = {
        ...payload,
        clubId: this.$route.params.id,
      };
      getEmailCampaignRecipientActivityService(payload)
        .then((res) => {
          this.loading = false;
          this.recipientActivityData = res.data?.data?.items;
          this.total = res.data?.data?.size || 0;
          this.take = res.data?.data?.limit || 0;
          this.skip = res.data?.data?.offset || 0;
          this.filterReloader(payload);

          if (type == "resendEmail") {
            this.showNotification(
              "Email campaign sent to the recipient.",
              "success"
            );
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    isNameLimit(name) {
      return name.length <= 30 ? name : name.substring(0, 30) + "...";
    },

    filterReloader(payload) {
      if (payload.searchQuery) {
        this.searchText = payload.searchQuery;
      }
      if (payload.campaignEmailStatus) {
        this.emailStatusFilterId = payload.campaignEmailStatus;
      }
    },
    dataStateChange(event) {
      this.take = event.data.take;
      this.skip = event.data.skip;

      let payload = {
        campaignId: this.campaignId,
        limit: event.data.take,
        offset: event.data.skip,
        searchQuery: this.searchWord,
        ...(this.emailStatusValue.id != 0 && {
          campaignEmailStatus: this.emailStatusValue.id,
        }),
      };

      this.getEmailCampaignRecipientActivity(payload);
    },
    resendEmailHandler(data) {
      const payload = {
        campaignMemberId: data.campaignMemberId,
        clubId: this.$route.params.id,
      };
      this.actionLoadingStatus = true;
      resendCampaignService(payload)
        .then((res) => {
          this.actionLoadingStatus = false;

          let payload = {
            campaignId: this.campaignId,
            limit: this.take,
            offset: this.skip,
            searchQuery: this.searchWord,
            ...(this.emailStatusValue.id != 0 && {
              campaignEmailStatus: this.emailStatusValue.id,
            }),
          };
          this.getEmailCampaignRecipientActivity(payload, "resendEmail");
        })
        .catch((err) => {
          this.actionLoadingStatus = false;
        });
    },
    handleEmailStatusValue(e) {
      this.emailStatusValue = e.value;
      let payload = {
        campaignId: this.campaignId,
        limit: this.take,
        offset: this.skip,
        searchQuery: this.searchWord,
        ...(this.emailStatusValue.id != 0 && {
          campaignEmailStatus: this.emailStatusValue.id,
        }),
      };
      this.getEmailCampaignRecipientActivity(payload);
    },
    onSearch(e) {
      this.searchWord = e.value;
      this.isSearching = true;
      let payload = {
        campaignId: this.campaignId,
        // limit: this.take,
        // offset: this.skip,
        searchQuery: this.searchWord,
        ...(this.emailStatusValue.id != 0 && {
          campaignEmailStatus: this.emailStatusValue.id,
        }),
      };

      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.getEmailCampaignRecipientActivity(payload);
      }, 500);
    },
    async exportReport() {
      this.actionLoadingStatus = true;
      const payload = {
        campaignId: this.campaignId,
      };

      let token = null;

      if (configuration.enableIdentityServer) {
        token = await auth.getAccessToken();
      } else {
        token = localStorage.getItem("clubeez_session");
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      axios({
        url: `${configuration.apiBaseUrl}/api/v1/Campaign/export-campaign-recipient-activity`,
        method: "POST",
        responseType: "blob",
        headers: headers,
        data: payload,
      })
        .then((response) => {
          this.actionLoadingStatus = false;

          let fileURL = window.URL.createObjectURL(new Blob([response.data]));
          let fileLink = document.createElement("a");
          fileLink.href = fileURL;
          let filename = `Campaign Recipient Activity.xlsx`;
          fileLink.setAttribute("download", filename);
          document.body.appendChild(fileLink);
          fileLink.click();

          this.showNotification(
            "Email campaign recipient activity exported.",
            "success"
          );
        })
        .catch((err) => {
          this.actionLoadingStatus = false;
        });
    },
    showNotification(message, type) {
      this.notifyMessage = message;
      this.notifyType = type;
      this.isNotify = true;
      setTimeout(
        () => (
          (this.notifyMessage = ""),
          (this.notifyType = "success"),
          (this.isNotify = false)
        ),
        4000
      );
    },
  },
};
</script>
