<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8 1C4.13437 1 1 4.13437 1 8C1 11.8656 4.13437 15 8 15C11.8656 15 15 11.8656 15 8C15 4.13437 11.8656 1 8 1ZM8 4H7V9H12V8H8V4ZM2 8C2 11.3094 4.69062 14 8 14C11.3094 14 14 11.3094 14 8C14 4.69062 11.3094 2 8 2C4.69062 2 2 4.69062 2 8Z" fill="#FFC107"/>
    </svg>      
</template>
<script>
    export default {
        name: "scheduledIcon"
    }
</script>
<style scoped></style>