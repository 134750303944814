<template>
  <AppSnackBar :message="notifyMessage" :notificationType="notifyType" v-if="isNotify"></AppSnackBar>
  <app-loader v-if="loading || actionLoadingStatus || isMultivariantWinnerLoading || isSearching"></app-loader>
  <div v-if="multivariantTableData?.length != 0 || searchText" class="dashboard-right-inner"
    :class="multivariantWinnerData ? 'multivariant-info-on' : 'multivariant-info-off'">
    <div class="p-24 bg-white">
      <div v-if="multivariantWinnerData" class="multivariant-info-wrap d-flex justify-content-between">
        <div class="multivariant-info-box bg-white py-20 px-24 d-flex justify-content-between">
          <div class="icon pe-3">
            <span>
              <component :is="'winnerIcon'"></component>
            </span>
          </div>
          <div class="content-center pe-3">
            <h5 class="font-16 font-w-400 base-color">Winner</h5>
          </div>
          <div class="content-end">
            <h5 class="font-16 font-w-600 base-color">Variation #{{ multivariantWinnerData?.serialNo }}</h5>
            <h6 class="font-12 font-w-400 text-color-2">{{ multivariantWinnerData?.uniqueSent }} Recipients</h6>
          </div>
        </div>
        <div class="multivariant-info-box bg-white py-20 px-24 d-flex justify-content-between">
          <div class="icon pe-3">
            <span>
              <component :is="'statusIcon'"></component>
            </span>
          </div>
          <div class="content-center pe-3">
            <h5 class="font-16 font-w-400 base-color">Status</h5>
          </div>
          <div class="content-end">
            <h5 class="font-16 font-w-600 text-color-green-1">Completed</h5>
            <h6 class="font-12 font-w-400 text-color-2">Ended on {{ new
              Date(multivariantWinnerData?.completedDate)?.toLocaleString('en-US', {
                month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true
              }) }}</h6>
          </div>
        </div>
        <div class="multivariant-info-box bg-white py-20 px-24 d-flex justify-content-between">
          <div class="icon pe-3">
            <span>
              <component :is="'metricIcon'"></component>
            </span>
          </div>
          <div class="content-center pe-3">
            <h5 class="font-16 font-w-400 base-color">Winning Metric</h5>
          </div>
          <div class="content-end">
            <h5 class="font-16 font-w-600 base-color">{{ multivariantWinnerData?.campaignTestMetric == 1 ? `Open Rate:
              ${this.percentageCalculation(multivariantWinnerData?.metricResult,
              multivariantWinnerData?.totalAudience)}%` :
              `Click Rate: ${this.percentageCalculation(multivariantWinnerData?.metricResult,
                multivariantWinnerData?.totalAudience)}%` }}
            </h5>
            <h6 class="font-12 font-w-400 text-color-2">{{ multivariantWinnerData?.totalAudience }} recipients</h6>
          </div>
        </div>
        <div class="multivariant-info-box bg-white py-20 px-24 d-flex justify-content-between">
          <div class="icon pe-3">
            <span>
              <component :is="'testIcon'"></component>
            </span>
          </div>
          <div class="content-center pe-3">
            <h5 class="font-16 font-w-400 base-color">Test Size</h5>
          </div>
          <div class="content-end">
            <h5 class="font-16 font-w-600 base-color">Audience: {{ multivariantWinnerData?.totalAudience }}</h5>
            <h6 class="font-12 font-w-400 text-color-2">{{ multivariantWinnerData?.campaignOptimizationType == '1' ?
              `${multivariantWinnerData?.totalAudience} of ${multivariantWinnerData?.campaignAudience} recipients` :
              `${multivariantWinnerData?.ratioCount}% of ${multivariantWinnerData?.campaignAudience} recipients` }}
            </h6>
          </div>
        </div>
      </div>
      <Grid ref="grid" :class="'max-height-100-395 min-h-500-h-840 multivariant-results-table-wrap sorting-btn-th'"
        :data-items="multivariantTableData" :take="take" :skip="skip" :total="total" :pageable="gridPageable"
        :columns="columns" @datastatechange="dataStateChange">
        <!-- :sortable="true" :sort="sort"
        @sortchange="sortChangeHandler" -->
        <GridNoRecords> No Variation found.</GridNoRecords>
        <template v-slot:bounceCell="{ props }">
          <td>
            <span> {{ this.percentageCalculation(props.dataItem?.uniqueBounced, props.dataItem?.uniqueSent) }}% </span>
            <span class="opacity-8">({{ props.dataItem?.uniqueBounced || 0 }} Bounces)</span>
          </td>
        </template>
        <template v-slot:openCell="{ props }">
          <td>
            <span> {{ this.percentageCalculation(props.dataItem?.uniqueOpened, props.dataItem?.uniqueSent) }}% </span>
            <span class="opacity-8">({{ props.dataItem?.uniqueOpened || 0 }} Opens)</span>
          </td>
        </template>
        <template v-slot:clicksCell="{ props }">
          <td>
            <span> {{ this.percentageCalculation(props.dataItem?.uniqueClicked, props.dataItem?.uniqueSent) }}%
            </span>
            <span class="opacity-8">({{ props.dataItem?.uniqueClicked || 0 }} Clicks)</span>
          </td>
        </template>
        <template v-slot:revenueCell="{ props }">
          <td>
            <span> ${{ props.dataItem.revenue.toFixed(2) }} </span>
          </td>
        </template>

        <template v-slot:actionCell="{ props }">
          <td>
            <div class="d-flex">
              <kbutton :disabled="multivariantWinnerData" @click="chooseWinnerModalHandler(props.dataItem)"
                :class="'h-38 w-38 p-0'" :icon="''">
                <component :is="'trophyIcon'"></component>
              </kbutton>
            </div>
          </td>
        </template>
        <toolbar>
          <div class="search-box-wrap width-260 w-100-vsm me-3 mr-0-vsm">
            <span class="k-icon k-i-zoom"></span>
            <span class="k-textbox k-grid-search k-display-flex">
              <k-input :class="'mb-0 width-260 w-100-vsm'" placeholder="Search by variation number" :value="searchWord"
                @input="onSearch"></k-input>
            </span>
          </div>
          <div class="ms-auto ml-unset-767 w-100-vsm">
            <div class="ms-auto ml-unset-767 w-100-vsm">
              <div class="d-flex w-100-vsm">
                <kbutton :class="'w-100-vsm'" @click="exportReport">Export</kbutton>
              </div>
            </div>
          </div>
        </toolbar>
      </Grid>
    </div>
  </div>

  <div v-if="multivariantTableData?.length == 0 && !searchText && !loading" class="min-h-100vh--160">
    <div class="dashboard-content bg-white radius-5 min-h-100vh--160 d-flex justify-content-center align-items-center">
      <div class="width-747 text-center">
        <component :is="'multivariantEmptyState'"></component>
        <p class="color-light2 mt-3 mb-5">No variations were created for your email.</p>
      </div>
    </div>
  </div>

  <WarningModal v-if="chooseWinnerModalVisibility" :modalDetails="chooseWinnerModalDetails"
    :onCancel="onCancelChooseWinner" :onConfirm="onConfirmChooseWinner" />
</template>

<style lang="scss">
@import "./emailCampaignMultivariantAnalytics.scss";
</style>

<script>
import {
  getEmailCampaignMultivariantResultService,
  getEmailCampaignMultivariantWinnerService,
  updateMultivariantWinnerService,
} from "@/services/clubDetails/emailCampaign.service";
import { Grid, GridToolbar, GridNoRecords } from "@progress/kendo-vue-grid";
import { Input } from "@progress/kendo-vue-inputs";
import { Button } from "@progress/kendo-vue-buttons";
import AppSnackBar from "../../../../common/AppSnackBar/AppSnackBar.vue";
import AppLoader from "@/components/common/AppLoader/AppLoader.vue";
import trophyIcon from "../../../../svg-icons/trophyIcon.vue";
import winnerIcon from "../../../../svg-icons/winnerIcon.vue";
import statusIcon from "../../../../svg-icons/statusIcon.vue";
import metricIcon from "../../../../svg-icons/metricIcon.vue";
import testIcon from "../../../../svg-icons/testIcon.vue";
import multivariantEmptyState from "../../../../svg-icons/multivariantEmptyState.vue";
import WarningModal from "../../warningModal/WarningModal.vue";
import { configuration } from "@/configurationProvider";
import { auth } from "@/services/auth.service";
import axios from "axios";

export default {
  name: "emailCampaignMultivariantAnalytics",
  components: {
    Grid,
    toolbar: GridToolbar,
    GridNoRecords,
    "k-input": Input,
    kbutton: Button,
    AppSnackBar,
    "app-loader": AppLoader,
    trophyIcon,
    winnerIcon,
    statusIcon,
    metricIcon,
    testIcon,
    multivariantEmptyState,
    WarningModal,
  },
  data: function () {
    return {
      loading: false,
      isMultivariantWinnerLoading: false,
      actionLoadingStatus: false,
      multivariantTableData: [],
      multivariantWinnerData: null,
      campaignId: this.$route.params.campaignId,

      chooseWinnerModalVisibility: false,
      selectedWinnerData: null,
      chooseWinnerModalDetails: {
        title: "Choose as Winner",
        message: "You are about to end your multivariate testing on Campaign_Title. You will not gather further analytics from this test and selected_Variation will be sent to the remainder of recipients.",
        confirmButton: "Choose Winner",
        cancelButton: "Cancel",
      },

      gridPageable: {
        buttonCount: 5,
        info: true,
        type: "numeric",
        pageSizes: [10, 50, 100, 200, 500, 1000],
        previousNext: true,
      },

      total: 0,
      skip: 0,
      take: 50,
      timer: null,

      notifyMessage: "",
      notifyType: "",
      isNotify: false,

      sort: [{ field: "variationNo", dir: "asc" }],
      searchWord: "",
      searchText: "",
      isSearching: false,
    };
  },
  props: {
    campaignDetails: {
      type: Object,
      default: () => { },
    }
  },
  computed: {
    columns() {
      return [
        {
          field: "variationNo",
          title: "Variations",
        },
        {
          field: "uniqueDelivered",
          title: "Successful Deliveries",
        },
        {
          field: "strTotalBounced",
          title: "Bounce",
          cell: 'bounceCell'
        },
        {
          field: "strTotalOpened",
          title: "Open",
          cell: 'openCell'
        },
        {
          field: "strTotalClicked",
          title: "Clicks",
          cell: 'clicksCell'
        },
        {
          field: "strRevenue",
          title: "Revenue",
          cell: 'revenueCell'
        },
        {
          // field: "action",
          title: "Action",
          cell: "actionCell",
        },
      ];
    },
  },
  mounted() {
    const payload = {
      campaignId: this.campaignId,
      limit: this.take,
      offset: this.skip,
    };
    this.getMultivariantResult(payload);
    this.getMultivariantWinner(payload);
  },

  methods: {
    async exportReport() {
      this.actionLoadingStatus = true;
      const payload = {
        campaignId: this.campaignId,
      };

      let token = null;

      if (configuration.enableIdentityServer) {
        token = await auth.getAccessToken();
      } else {
        token = localStorage.getItem("clubeez_session");
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      axios({
        url: `${configuration.apiBaseUrl}/api/v1/Campaign/export-campaign-multivariant`,
        method: "POST",
        responseType: "blob",
        headers: headers,
        data: payload,
      })
        .then((response) => {
          this.actionLoadingStatus = false;

          let fileURL = window.URL.createObjectURL(new Blob([response.data]));
          let fileLink = document.createElement("a");
          fileLink.href = fileURL;
          let filename = `Campaign Multivariate Result.xlsx`;
          fileLink.setAttribute("download", filename);
          document.body.appendChild(fileLink);
          fileLink.click();

          this.showNotification(
            "Email campaign multivariate result exported.",
            "success"
          );
        })
        .catch((err) => {
          this.actionLoadingStatus = false;
          this.showNotification(
            "Error while exporting email campaign multivariate result.",
            "error"
          )
        });
    },
    onSearch(e) {
      this.searchWord = e.value;
      let payload = {
        limit: this.take,
        offset: 0,
        campaignId: this.campaignId,
        searchQuery: this.searchWord,
      };

      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.isSearching = true;
        this.getMultivariantResult(payload);
      }, 500);
    },
    updateWinnerHandler(payload) {
      this.isMultivariantWinnerLoading = true;
      payload = {
        ...payload,
        clubId: this.$route.params.id,
      }
      updateMultivariantWinnerService(payload)
        .then((res) => {
          this.isMultivariantWinnerLoading = false;
          this.chooseWinnerModalVisibility = false;
          const payloadForRefetch = {
            campaignId: this.campaignId,
            limit: this.take,
            offset: this.skip,
          };
          this.getMultivariantResult(payloadForRefetch);
          this.getMultivariantWinner(payloadForRefetch);
          this.showNotification(
            "Winner updated successfully",
            "success"
          );
        })
        .catch((err) => {
          this.isMultivariantWinnerLoading = false;
          this.chooseWinnerModalVisibility = false;
          this.showNotification(
            "Something went wrong, please try again later",
            "error"
          );
        });
    },
    chooseWinnerModalHandler(data) {
      this.chooseWinnerModalVisibility = true;
      this.chooseWinnerModalDetails.message = `You are about to end the multivariate testing of your campaign "${this.campaignDetails?.campaignName}". You will not gather further analytics from this test and "${data?.variationNo}" will be sent to the remainder of your recipients.`;
      this.selectedWinnerData = data;
    },
    onConfirmChooseWinner() {
      const payload = {
        campaignId: this.selectedWinnerData?.campaignId,
        id: this.selectedWinnerData?.campaignCombinationId,
      };
      this.updateWinnerHandler(payload);
    },
    onCancelChooseWinner() {
      this.chooseWinnerModalVisibility = false;
    },
    percentageCalculation(value, total) {
      if (value == 0 && total == 0) {
        return 0;
      } else {
        if (isFinite(value / total)) {
          return Math.floor((value / total) * 100);
        } else {
          return 0;
        }
      }
    },
    sortChangeHandler: function (e) {
      ;
      this.sort = e.sort;
      let sortBy = "";
      let sortDir = "";

      if (e.sort.length > 0) {
        sortBy = e.sort[0].field;
        sortDir = e.sort[0].dir;
      } else {
        sortBy = "";
        sortDir = "";
      }

      let payload = {
        campaignId: this.campaignId,
        limit: this.take,
        offset: this.skip,
        searchQuery: this.searchWord,
      };

      if (sortBy && sortDir) {
        payload.orderBy = `${sortBy} ${sortDir}`;
      }

      this.getMultivariantResult(payload);
    },

    getMultivariantResult(payload) {
      this.loading = true;
      payload = {
        ...payload,
        clubId: this.$route.params.id,
      }
      getEmailCampaignMultivariantResultService(payload)
        .then((res) => {
          this.searchText = this.searchWord;
          this.loading = false;
          this.isSearching = false;
          this.multivariantTableData = res.data?.data?.items;
          this.total = res.data?.data?.size || 0;
          this.take = res.data?.data?.limit || 0;
          this.skip = res.data?.data?.offset || 0;
        })
        .catch((err) => {
          this.searchText = this.searchWord;
          this.loading = false;
          this.isSearching = false;
        });
    },
    getMultivariantWinner(payload) {
      this.isMultivariantWinnerLoading = true;
      payload = {
        ...payload,
        clubId: this.$route.params.id,
      }
      getEmailCampaignMultivariantWinnerService(payload)
        .then((res) => {
          this.isMultivariantWinnerLoading = false;
          this.multivariantWinnerData = res.data?.data;
        })
        .catch((err) => {
          this.isMultivariantWinnerLoading = false;
        });
    },

    dataStateChange(event) {
      this.take = event.data.take;
      this.skip = event.data.skip;

      let payload = {
        campaignId: this.campaignId,
        limit: event.data.take,
        offset: event.data.skip,
        searchQuery: this.searchWord,
      };

      this.getMultivariantResult(payload);
    },

    showNotification(message, type) {
      this.notifyMessage = message;
      this.notifyType = type;
      this.isNotify = true;
      setTimeout(
        () => (
          (this.notifyMessage = ""),
          (this.notifyType = "success"),
          (this.isNotify = false)
        ),
        4000
      );
    },
  },
};
</script>
