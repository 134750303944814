<template>
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="60" height="60" rx="30" fill="#198754" fill-opacity="0.08"/>
    <path d="M34.3375 16.667C35.8818 16.667 37.1537 17.834 37.3192 19.3342L39.0095 19.3337C40.2338 19.3337 41.2378 20.2765 41.3351 21.4756L41.3429 21.667V25.667C41.3429 27.9777 39.5343 29.866 37.2554 29.9935L36.9751 30.0013C36.0846 32.7882 33.6438 34.8823 30.667 35.2693L30.6661 37.3337H33.0041C35.3148 37.3337 37.2031 39.1423 37.3306 41.4211L37.3375 41.667V42.3337C37.3375 42.8399 36.9613 43.2583 36.4732 43.3245L36.3375 43.3337H23.0001C22.4938 43.3337 22.0754 42.9575 22.0092 42.4694L22.0001 42.3337V41.667C22.0001 39.3563 23.8087 37.468 26.0875 37.3405L26.3334 37.3337H28.6661L28.6666 35.2688C25.6915 34.8805 23.2525 32.787 22.3625 30.0013L22.3275 30.0003C19.9342 30.0003 17.9941 28.0602 17.9941 25.667V21.667C17.9941 20.3783 19.0388 19.3337 20.3275 19.3337L22.0183 19.3342C22.1839 17.834 23.4557 16.667 25.0001 16.667H34.3375ZM33.0041 39.3337H26.3334C25.2271 39.3337 24.3006 40.1036 24.0606 41.137L24.0237 41.3337H35.3138C35.1616 40.2694 34.2912 39.4368 33.2093 39.3426L33.0041 39.3337ZM34.3375 18.667H25.0001C24.4478 18.667 24.0001 19.1147 24.0001 19.667V27.665C24.0001 30.7957 26.5381 33.3337 29.6688 33.3337C32.7995 33.3337 35.3375 30.7957 35.3375 27.665V19.667C35.3375 19.1147 34.8897 18.667 34.3375 18.667ZM39.0095 21.3337H37.3375V27.9775C38.4042 27.8274 39.2394 26.9561 39.3339 25.8725L39.3429 25.667V21.667C39.3429 21.5092 39.2332 21.377 39.086 21.3425L39.0095 21.3337ZM22.0001 21.3337H20.3275C20.1434 21.3337 19.9941 21.4829 19.9941 21.667V25.667C19.9941 26.8445 20.8664 27.8184 22.0001 27.9775V21.3337Z" fill="#198754"/>
    </svg>
</template>
<script>
    export default {
        name: "winnerIcon"
    }
</script>
<style scoped></style>