<template>
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="60" height="60" rx="30" fill="#0DCAF0" fill-opacity="0.08"/>
    <path d="M25.7082 34.877L21.0795 29.6696C20.7125 29.2568 20.0805 29.2197 19.6677 29.5866C19.2549 29.9535 19.2177 30.5856 19.5846 30.9983L24.918 36.9984C25.3002 37.4284 25.9656 37.4479 26.3725 37.0411L40.3725 23.0411C40.763 22.6506 40.763 22.0174 40.3725 21.6269C39.982 21.2364 39.3488 21.2364 38.9583 21.6269L25.7082 34.877Z" fill="#0DCAF0"/>
    </svg>
</template>
<script>
    export default {
        name: "statusIcon"
    }
</script>
<style scoped></style>