<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_8768_52175)">
        <path d="M0.721126 0.0514945L16.0756 7.61746C16.3233 7.73952 16.4252 8.03927 16.3031 8.28697C16.2544 8.3858 16.1744 8.46578 16.0756 8.51448L0.721442 16.0803C0.473739 16.2023 0.173989 16.1005 0.0519328 15.8528C-0.00143915 15.7445 -0.0138112 15.6205 0.0171017 15.5038L1.9858 8.07012L0.016755 0.627888C-0.0538755 0.360932 0.105278 0.087263 0.372235 0.0166325C0.488927 -0.0142416 0.61285 -0.001859 0.721126 0.0514945ZM1.26445 1.43403L2.87357 7.51612L2.93555 7.50412L3 7.5H10C10.2761 7.5 10.5 7.72386 10.5 8C10.5 8.24546 10.3231 8.44961 10.0899 8.49194L10 8.5H3C2.9686 8.5 2.93787 8.4971 2.90807 8.49157L1.26508 14.6976L14.7234 8.06597L1.26445 1.43403Z" fill="#198754"/>
        </g>
        <defs>
        <clipPath id="clip0_8768_52175">
        <rect width="16" height="16" fill="white"/>
        </clipPath>
        </defs>
    </svg>        
</template>
<script>
    export default {
        name: "sentIcon"
    }
</script>
<style scoped></style>