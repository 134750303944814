<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.74275 1.07125C7.90109 0.976249 8.09891 0.976249 8.25725 1.07125L15.029 5.1343C15.6314 5.49575 16 6.14676 16 6.84929V12.5C16 13.8807 14.8807 15 13.5 15H2.5C1.11929 15 0 13.8807 0 12.5V6.84929C0 6.14676 0.368598 5.49575 0.971008 5.1343L1.18358 5.48859L0.971008 5.1343L7.74275 1.07125ZM8 2.0831L1.4855 5.99179C1.48096 5.99452 1.47644 5.99728 1.47195 6.00007L8 9.91691L14.5281 6.00007C14.5236 5.99728 14.519 5.99452 14.5145 5.99179L8 2.0831ZM15 6.88309L8.25725 10.9287C8.09891 11.0238 7.90109 11.0238 7.74275 10.9287L1 6.88309V12.5C1 13.3284 1.67157 14 2.5 14H13.5C14.3284 14 15 13.3284 15 12.5V6.88309Z" fill="#198754"/>
    </svg>
</template>
<script>
    export default {
        name: "openedIcon"
    }
</script>
<style scoped></style>