<template>
    <svg width="250" height="204" viewBox="0 0 250 204" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle opacity="0.05" cx="120.074" cy="92.5" r="92" fill="#253858"/>
    <rect x="117" y="35.5" width="2.8589" height="2.8589" rx="1.42945" fill="#A4C3F3"/>
    <g filter="url(#filter0_dd_6098_160906)">
    <rect x="23.0742" y="31.0996" width="110.844" height="104.4" rx="3.69388" fill="white"/>
    <rect x="23.5742" y="31.5996" width="109.844" height="103.4" rx="3.19388" stroke="#20C997"/>
    </g>
    <rect x="123.074" y="21.5" width="20" height="20" rx="10" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M133.074 21.5C127.551 21.5 123.074 25.9773 123.074 31.5C123.074 37.0227 127.551 41.5 133.074 41.5C138.597 41.5 143.074 37.0227 143.074 31.5C143.074 25.9773 138.597 21.5 133.074 21.5ZM137.409 29.8091C137.489 29.7179 137.549 29.6116 137.587 29.4965C137.626 29.3815 137.64 29.2599 137.631 29.1391C137.621 29.0182 137.588 28.9005 137.532 28.7928C137.476 28.6852 137.4 28.5897 137.307 28.5121C137.213 28.4345 137.106 28.3763 136.99 28.341C136.874 28.3056 136.752 28.2938 136.631 28.3062C136.511 28.3186 136.394 28.355 136.288 28.4133C136.181 28.4716 136.088 28.5505 136.012 28.6455L132.103 33.3355L130.081 31.3118C129.909 31.1462 129.679 31.0546 129.441 31.0567C129.203 31.0587 128.975 31.1543 128.806 31.3229C128.638 31.4914 128.542 31.7195 128.54 31.9578C128.538 32.1962 128.63 32.4258 128.795 32.5973L131.522 35.3245C131.612 35.4138 131.719 35.4835 131.836 35.5291C131.954 35.5748 132.08 35.5954 132.206 35.5897C132.332 35.584 132.456 35.552 132.569 35.4959C132.682 35.4397 132.782 35.3606 132.863 35.2636L137.409 29.8091Z" fill="#20C997"/>
    <rect x="69.0742" y="58.5" width="31" height="6" rx="3" fill="#A4C3F3"/>
    <rect x="69.1328" y="48.5" width="46.9812" height="6.4484" rx="3.2242" fill="#A4C3F3"/>
    <rect x="35.0742" y="43.5" width="25" height="26" rx="4" fill="#EDF3FD"/>
    <path d="M51.2617 61.5L49.8398 57.8672H45.2617L43.8555 61.5H42.5117L47.0273 50.0312H48.1445L52.6367 61.5H51.2617ZM49.4258 56.6719L48.0977 53.1328C47.9258 52.6849 47.7487 52.1354 47.5664 51.4844C47.4518 51.9844 47.2878 52.5339 47.0742 53.1328L45.7305 56.6719H49.4258Z" fill="#A4C3F3"/>
    <rect opacity="0.5" x="34.6738" y="78.7891" width="87.6444" height="5.15556" rx="2.57778" fill="#B9C8DF"/>
    <rect opacity="0.5" x="34.6738" y="104.566" width="52.8444" height="5.15556" rx="2.57778" fill="#B9C8DF"/>
    <rect opacity="0.5" x="34.6738" y="91.6777" width="78.6222" height="5.15556" rx="2.57778" fill="#B9C8DF"/>
    <rect opacity="0.5" x="34.6738" y="117.455" width="87.6444" height="5.15556" rx="2.57778" fill="#B9C8DF"/>
    <g filter="url(#filter1_dd_6098_160906)">
    <rect x="116.074" y="69.5" width="110.844" height="104.4" rx="3.69388" fill="white"/>
    </g>
    <rect x="162.074" y="96.5" width="31" height="6" rx="3" fill="#A4C3F3"/>
    <rect x="162.133" y="86.9004" width="46.9812" height="6.4484" rx="3.2242" fill="#A4C3F3"/>
    <rect x="128.074" y="81.9004" width="25" height="26" rx="4" fill="#EDF3FD"/>
    <path d="M136.957 88.4785H140.184C141.699 88.4785 142.796 88.7051 143.473 89.1582C144.15 89.6113 144.488 90.3275 144.488 91.3066C144.488 91.9837 144.298 92.5436 143.918 92.9863C143.543 93.4238 142.993 93.7077 142.27 93.8379V93.916C144.004 94.2129 144.871 95.1243 144.871 96.6504C144.871 97.6712 144.525 98.4681 143.832 99.041C143.145 99.6139 142.181 99.9004 140.941 99.9004H136.957V88.4785ZM138.285 93.3691H140.473C141.41 93.3691 142.085 93.2233 142.496 92.9316C142.908 92.6348 143.113 92.1374 143.113 91.4395C143.113 90.7988 142.884 90.3379 142.426 90.0566C141.967 89.7702 141.238 89.627 140.238 89.627H138.285V93.3691ZM138.285 94.4941V98.7676H140.668C141.59 98.7676 142.283 98.5905 142.746 98.2363C143.215 97.877 143.449 97.3171 143.449 96.5566C143.449 95.8483 143.21 95.3275 142.73 94.9941C142.257 94.6608 141.533 94.4941 140.559 94.4941H138.285Z" fill="#A4C3F3"/>
    <rect opacity="0.5" x="127.674" y="117.189" width="87.6444" height="5.15556" rx="2.57778" fill="#B9C8DF"/>
    <rect opacity="0.5" x="127.674" y="142.967" width="52.8444" height="5.15556" rx="2.57778" fill="#B9C8DF"/>
    <rect opacity="0.5" x="127.674" y="130.078" width="78.6222" height="5.15556" rx="2.57778" fill="#B9C8DF"/>
    <rect opacity="0.5" x="127.674" y="155.855" width="87.6444" height="5.15556" rx="2.57778" fill="#B9C8DF"/>
    <defs>
    <filter id="filter0_dd_6098_160906" x="0.910919" y="16.3241" width="155.17" height="148.727" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="7.38777"/>
    <feGaussianBlur stdDeviation="11.0816"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.04 0 0 0 0 0.168627 0 0 0 0 0.36 0 0 0 0.08 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6098_160906"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="1.84694"/>
    <feGaussianBlur stdDeviation="1.84694"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
    <feBlend mode="normal" in2="effect1_dropShadow_6098_160906" result="effect2_dropShadow_6098_160906"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_6098_160906" result="shape"/>
    </filter>
    <filter id="filter1_dd_6098_160906" x="93.9109" y="54.7245" width="155.17" height="148.727" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="7.38777"/>
    <feGaussianBlur stdDeviation="11.0816"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.04 0 0 0 0 0.168627 0 0 0 0 0.36 0 0 0 0.08 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6098_160906"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="1.84694"/>
    <feGaussianBlur stdDeviation="1.84694"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
    <feBlend mode="normal" in2="effect1_dropShadow_6098_160906" result="effect2_dropShadow_6098_160906"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_6098_160906" result="shape"/>
    </filter>
    </defs>
    </svg>
</template>
<script>
    export default {
        name: "multivariantEmptyState"
    }
</script>
<style scoped></style>