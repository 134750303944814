<template>
    <svg width="217" height="185" viewBox="0 0 217 185" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle opacity="0.05" cx="108" cy="92.5" r="92" fill="#253858"/>
        <g filter="url(#filter0_bdd_6009_160043)">
        <rect x="31.7812" y="53.5176" width="154.563" height="98.2819" rx="1.93432" fill="white" fill-opacity="0.15" shape-rendering="crispEdges"/>
        </g>
        <g filter="url(#filter1_bd_6009_160043)">
        <rect x="28.0996" y="44.4238" width="161.928" height="102.965" rx="1.88497" fill="white" fill-opacity="0.15" shape-rendering="crispEdges"/>
        </g>
        <g filter="url(#filter2_dd_6009_160043)">
        <rect x="23" y="33.5" width="170.842" height="108.97" rx="3.69388" fill="white"/>
        </g>
        <line x1="36" y1="87.0383" x2="180" y2="87.0383" stroke="black" stroke-opacity="0.04" stroke-width="0.923471"/>
        <rect opacity="0.2" x="38" y="47.5" width="27.7041" height="27.7041" rx="13.8521" fill="#14C57B"/>
        <rect opacity="0.6" x="76" y="49.5" width="55" height="6" rx="3" fill="#C7CBDB"/>
        <rect opacity="0.3" x="76" y="62.5" width="104" height="3.5" rx="1.75" fill="#C7CBDB"/>
        <rect opacity="0.3" x="76" y="71.5" width="94" height="3.5" rx="1.75" fill="#C7CBDB"/>
        <mask id="mask0_6009_160043" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="38" y="45" width="28" height="29">
        <rect x="38" y="45.5" width="27.7041" height="27.7041" rx="13.8521" fill="#A4C3F3"/>
        </mask>
        <g mask="url(#mask0_6009_160043)">
        <path d="M48.6959 63.4591C49.846 62.9912 50.9474 62.7573 52 62.7573C53.0526 62.7573 54.1442 62.9912 55.2749 63.4591C56.425 63.9074 57 64.5019 57 65.2427C57 65.9371 56.4371 66.5 55.7427 66.5H48.2573C47.5629 66.5 47 65.9371 47 65.2427C47 64.5019 47.5653 63.9074 48.6959 63.4591ZM53.7544 60.769C53.2671 61.2563 52.6823 61.5 52 61.5C51.3177 61.5 50.7329 61.2563 50.2456 60.769C49.7583 60.2817 49.5146 59.6969 49.5146 59.0146C49.5146 58.3324 49.7583 57.7476 50.2456 57.2602C50.7329 56.7534 51.3177 56.5 52 56.5C52.6823 56.5 53.2671 56.7534 53.7544 57.2602C54.2417 57.7476 54.4854 58.3324 54.4854 59.0146C54.4854 59.6969 54.2417 60.2817 53.7544 60.769Z" fill="#14C57B"/>
        </g>
        <rect opacity="0.2" x="38" y="98.5" width="27.7041" height="27.7041" rx="13.8521" fill="#9B5FFC"/>
        <rect opacity="0.6" x="76" y="100.5" width="55" height="6" rx="3" fill="#C7CBDB"/>
        <rect opacity="0.3" x="76" y="113.5" width="104" height="3.5" rx="1.75" fill="#C7CBDB"/>
        <rect opacity="0.3" x="76" y="122.5" width="94" height="3.5" rx="1.75" fill="#C7CBDB"/>
        <mask id="mask1_6009_160043" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="38" y="96" width="28" height="29">
        <rect x="38" y="96.5" width="27.7041" height="27.7041" rx="13.8521" fill="#A4C3F3"/>
        </mask>
        <g mask="url(#mask1_6009_160043)">
        <path d="M48.6959 113.459C49.846 112.991 50.9474 112.757 52 112.757C53.0526 112.757 54.1442 112.991 55.2749 113.459C56.425 113.907 57 114.502 57 115.243C57 115.937 56.4371 116.5 55.7427 116.5H48.2573C47.5629 116.5 47 115.937 47 115.243C47 114.502 47.5653 113.907 48.6959 113.459ZM53.7544 110.769C53.2671 111.256 52.6823 111.5 52 111.5C51.3177 111.5 50.7329 111.256 50.2456 110.769C49.7583 110.282 49.5146 109.697 49.5146 109.015C49.5146 108.332 49.7583 107.748 50.2456 107.26C50.7329 106.753 51.3177 106.5 52 106.5C52.6823 106.5 53.2671 106.753 53.7544 107.26C54.2417 107.748 54.4854 108.332 54.4854 109.015C54.4854 109.697 54.2417 110.282 53.7544 110.769Z" fill="#9B5FFC"/>
        </g>
        <defs>
        <filter id="filter0_bdd_6009_160043" x="23.7421" y="45.4784" width="170.641" height="114.361" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="4.01957"/>
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_6009_160043"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="1.81524"/>
        <feGaussianBlur stdDeviation="1.81524"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"/>
        <feBlend mode="normal" in2="effect1_backgroundBlur_6009_160043" result="effect2_dropShadow_6009_160043"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4.01957"/>
        <feGaussianBlur stdDeviation="2.00978"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0"/>
        <feBlend mode="normal" in2="effect2_dropShadow_6009_160043" result="effect3_dropShadow_6009_160043"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_6009_160043" result="shape"/>
        </filter>
        <filter id="filter1_bd_6009_160043" x="20.3047" y="36.6289" width="177.518" height="118.556" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="3.89744"/>
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_6009_160043"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="1.76009"/>
        <feGaussianBlur stdDeviation="1.76009"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>
        <feBlend mode="normal" in2="effect1_backgroundBlur_6009_160043" result="effect2_dropShadow_6009_160043"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_6009_160043" result="shape"/>
        </filter>
        <filter id="filter2_dd_6009_160043" x="0.8367" y="18.7245" width="215.168" height="153.296" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="7.38777"/>
        <feGaussianBlur stdDeviation="11.0816"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.04 0 0 0 0 0.168627 0 0 0 0 0.36 0 0 0 0.08 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6009_160043"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="1.84694"/>
        <feGaussianBlur stdDeviation="1.84694"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
        <feBlend mode="normal" in2="effect1_dropShadow_6009_160043" result="effect2_dropShadow_6009_160043"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_6009_160043" result="shape"/>
        </filter>
        </defs>
    </svg>
</template>
<script>
    export default {
        name: "audienceBreakdownIcon"
    }
</script>
<style scoped></style>