<template src="./emailCampaignAnalyticsOverview.html" lang=""></template>

<style lang="scss">
@import "./emailCampaignAnalyticsOverview.scss";
</style>

<script>
import {
  Chart,
  ChartTitle,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartTooltip,
  ChartSeriesItemTooltip,
} from "@progress/kendo-vue-charts";
import appLineChart from "../../../../common/AppLineChart/appLineChart.vue";

import AppLoader from "@/components/common/AppLoader/AppLoader.vue";
import { ProgressBar } from "@progress/kendo-vue-progressbars";
export default {
  name: "emailCampaignAnalyticsOverview",
  components: {
    Chart,
    ChartTitle,
    ChartSeries,
    ChartSeriesItem,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartTooltip,
    ChartSeriesItemTooltip,
    appLineChart,
    AppLoader,
    ProgressBar,
  },
  data: function () {
    return {};
  },

  mounted() {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    ;
    var payload = {
      campaignId: this.$route.params.campaignId,
      timeZone: timeZone,
      clubId: this.$route.params.id,
    };
    this.$store.dispatch("getEmailCampaignAnalytics", payload);
  },

  computed: {
    getEmailCampaignAnalyticsData() {
      return this.$store.state.emailCampaignAnalytics
        .emailCampaignAnalyticsData;
    },
    getEmailCampaignAnalyticsLoading() {
      return this.$store.state.emailCampaignAnalytics
        .emailCampaignAnalyticsLoader;
    },
  },
  methods: {
    percentageCalc(value, total) {
      if (!value || !total) {
        return 0;
      } else {
        if (isFinite(value / total)) {
          return parseFloat(((value / total) * 100).toFixed(2));
        } else {
          return 0;
        }
      }
    }
  },
};
</script>
