<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.5844 2.58398L15 3.99961L6 12.9996L1 7.99961L2.41562 6.58398L6 10.1715L13.5844 2.58398Z" fill="#198754"/>
    </svg>     
</template>
<script>
    export default {
        name: "deliveredIcon"
    }
</script>
<style scoped></style>