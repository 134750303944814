<template src="./emailCampaignAnalyticsController.html" lang=""></template>

<style lang="scss">
@import "./emailCampaignAnalyticsController.scss";
</style>

<script>
import emailCampaignAnalyticsOverview from "../emailCampaignAnalyticsOverview/emailCampaignAnalyticsOverview.vue";
import Breadcrumb from "../../../../common/AppBreadCrumbs/BreadCrumbs.vue";
import AppPopUp from "../../../../common/AppPopUp/AppPopUp.vue";
import { Button } from "@progress/kendo-vue-buttons";
import { ProgressBar } from "@progress/kendo-vue-progressbars";
import emailCampaignRecipientActivity from "../emailCampaignRecipientActivity/emailCampaignRecipientActivity.vue";
import emailCampaignMultivariantAnalytics from "../emailCampaignMultivariantAnalytics/emailCampaignMultivariantAnalytics.vue";
import emailCampaignLinkActivity from "../emailCampaignLinkActivity/emailCampaignLinkActivity.vue";
import emailCampaignAudienceBreakdown from "../emailCampaignAudienceBreakdown/emailCampaignAudienceBreakdown.vue";
import emailCampaignAdvanceReport from "../emailCampaignAdvanceReport/emailCampaignAdvanceReport.vue";

export default {
  components: {
    emailCampaignAnalyticsOverview,
    emailCampaignRecipientActivity,
    Breadcrumb,
    AppPopUp,
    kbutton: Button,
    ProgressBar,
    emailCampaignLinkActivity,
    emailCampaignMultivariantAnalytics,
    emailCampaignAudienceBreakdown,
    emailCampaignAdvanceReport,
  },
  data: function () {
    return {
      moduleCode: "emailCampaign",
      itemCode: "allCampaign",
      popupSettings: {
        anchorAlign: {
          horizontal: "right",
          vertical: "bottom",
        },
        popupAlign: {
          horizontal: "right",
          vertical: "top",
        },
        popupClass: "popup-design",
      },
      voucherDropdownitems: [
        {
          text: "Download Report",
          dropId: 2,
        },
      ],
      tabNamesForAdvanceCampaign: [
        {
          name: "emailCampaignAnalyticsOverview",
          value: "Overview",
          id: 1,
        },
        {
          name: "emailCampaignMultivariantAnalytics",
          value: "Multivariate Result",
          id: 2,
        },
        {
          name: "emailCampaignRecipientActivity",
          value: "Recipients Activity",
          id: 3,
        },
        {
          name: "emailCampaignAudienceBreakdown",
          value: "Audience Breakdown",
          id: 4,
        },
        {
          name: "emailCampaignLinkActivity",
          value: "Link Activity",
          id: 5,
        },
        {
          name: "emailCampaignAdvanceReport",
          value: "Advanced Reports",
          id: 6,
        },
      ],
      tabNamesForRegularCampaign: [
        {
          name: "emailCampaignAnalyticsOverview",
          value: "Overview",
          id: 1,
        },
        {
          name: "emailCampaignRecipientActivity",
          value: "Recipients Activity",
          id: 3,
        },
        {
          name: "emailCampaignAudienceBreakdown",
          value: "Audience Breakdown",
          id: 4,
        },
        {
          name: "emailCampaignLinkActivity",
          value: "Link Activity",
          id: 5,
        },
        {
          name: "emailCampaignAdvanceReport",
          value: "Advanced Reports",
          id: 6,
        },
      ],
      activeTab: "emailCampaignAnalyticsOverview",
      campaignStatus: {
        1: "Draft",
        2: "Live",
        3: "Scheduled",
        4: "Paused",
        5: "Completed",
        6: "Cancelled",
      },

      campaignStatusColor: {
        1: "draft",
        2: "live",
        3: "scheduled",
        4: "paused",
        5: "live",
        6: "",
        7: "scheduled",
      },
    };
  },
  mounted() {
    if (!this.hasGetAccess()) {
      this.$store.commit("SHOW_NOTIFICATION", {
        message: "You do not have access to campaign analytics.",
        type: "warning",
      });
      this.$router.push(`/club-details/${this.$route.params.id}/dashboard`);
    }

    this.$store.commit(
      "SET_EMAIL_CAMPAIGN_NAME",
      this.getEmailCampaignAnalyticsData?.campaignName
    );
  },
  computed: {
    isCampaignExist() {
      return this.$store.state.emailCampaignAnalytics.isCampaignExist;
    },
    getEmailCampaignAnalyticsData() {
      return this.$store.state.emailCampaignAnalytics
        .emailCampaignAnalyticsData;
    },
    getEmailCampaignAnalyticsLoading() {
      return this.$store.state.emailCampaignAnalytics
        .emailCampaignAnalyticsLoader;
    },
  },
  methods: {
    hasGetAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasGetPermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    generateName(name) {
      //return 70 characters of name and add ... at the end
      return name.length > 70 ? name.substring(0, 70) + "..." : name;
    },
    doNavigateToCampaignHomepage(type) {
      if(type==1){
        this.$router.push({
        name: "AllCampaign",
      });
      }else if(type==2){
        this.$router.push({
        name: "flowAutomation",
      });
      }
    },

    doNavigateToCampaignView() {
      this.$router.push({
        name: "CampaignDetails",
        params: {
          ...this.$route.params,
          campaignId: this.$route.params.campaignId,
          campaignName: this.$route.query.campaignName,
        },
        query: {
          type: 2,
          campaignName: this.$route.query.campaignName,
        },
      });
    },

    percentageCalc(a, b) {
      return Math.ceil((a / b) * 100);
    },
    switchTabHandlers(tabName) {
      this.activeTab = tabName;
    },
  },
};
</script>
