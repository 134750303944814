<template>
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="60" height="60" rx="30" fill="#0052CC" fill-opacity="0.08"/>
    <path d="M23.3327 20.667C21.8599 20.667 20.666 21.8609 20.666 23.3337V36.667C20.666 38.1398 21.8599 39.3337 23.3327 39.3337C24.8054 39.3337 25.9993 38.1398 25.9993 36.667V23.3337C25.9993 21.8609 24.8054 20.667 23.3327 20.667ZM21.9993 23.3337C21.9993 22.5973 22.5963 22.0003 23.3327 22.0003C24.0691 22.0003 24.666 22.5973 24.666 23.3337V36.667C24.666 37.4034 24.0691 38.0003 23.3327 38.0003C22.5963 38.0003 21.9993 37.4034 21.9993 36.667V23.3337ZM27.3327 27.3337C27.3327 25.8609 28.5266 24.667 29.9993 24.667C31.4721 24.667 32.666 25.8609 32.666 27.3337V36.667C32.666 38.1398 31.4721 39.3337 29.9993 39.3337C28.5266 39.3337 27.3327 38.1398 27.3327 36.667V27.3337ZM29.9993 26.0003C29.263 26.0003 28.666 26.5973 28.666 27.3337V36.667C28.666 37.4034 29.263 38.0003 29.9993 38.0003C30.7357 38.0003 31.3327 37.4034 31.3327 36.667V27.3337C31.3327 26.5973 30.7357 26.0003 29.9993 26.0003ZM33.9993 31.3337C33.9993 29.8609 35.1933 28.667 36.666 28.667C38.1388 28.667 39.3327 29.8609 39.3327 31.3337V36.667C39.3327 38.1398 38.1388 39.3337 36.666 39.3337C35.1933 39.3337 33.9993 38.1398 33.9993 36.667V31.3337ZM36.666 30.0003C35.9296 30.0003 35.3327 30.5973 35.3327 31.3337V36.667C35.3327 37.4034 35.9296 38.0003 36.666 38.0003C37.4024 38.0003 37.9993 37.4034 37.9993 36.667V31.3337C37.9993 30.5973 37.4024 30.0003 36.666 30.0003Z" fill="#2990FF"/>
    </svg>
</template>
<script>
    export default {
        name: "metricIcon"
    }
</script>
<style scoped></style>