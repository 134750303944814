<template>
  <AppSnackBar :message="notifyMessage" :notificationType="notifyType" v-if="isNotify"></AppSnackBar>

  <app-loader v-if="loading || actionLoadingStatus || isActivitySummeryLoading"></app-loader>

  <div v-if="linkActivityData?.length != 0 || searchText" class="dashboard-right-inner">
    <div class="p-24 bg-white">
      <div class="link-activity-card-all d-flex justify-content-between mb-24">
        <div class="link-activity color-1">
          <div class="content w-100">
            <h4 class="link-activity-status">Clicked</h4>
            <div class="info d-flex align-items-center">
              <h2 class="mb-0">
                {{
                  Math.round(
                    (linkActivitySummeryData?.uniqueClicks /
                      linkActivitySummeryData?.totalMembers) *
                    100
                  ) || 0
                }}%
              </h2>
              <span class="link-activity-number ms-auto">{{
                linkActivitySummeryData?.totalMembers || 0
              }}
                Recipients</span>
            </div>
          </div>
        </div>
        <div class="link-activity color-2">
          <div class="content w-100">
            <h4 class="link-activity-status">Total Clicks</h4>
            <div class="info d-flex align-items-center">
              <h2 class="mb-0">
                {{ linkActivitySummeryData?.totalClicks || 0 }}
              </h2>
              <span class="link-activity-number ms-auto">Made by
                {{ linkActivitySummeryData?.totalMembers || 0 }}
                recipients</span>
            </div>
          </div>
        </div>
        <div class="link-activity color-3">
          <div class="content w-100">
            <h4 class="link-activity-status">Avg. clicks per person</h4>
            <div class="info d-flex align-items-center">
              <h2 class="mb-0">
                {{
                  linkActivitySummeryData?.totalClicks /
                  linkActivitySummeryData?.totalMembers
                  ? (
                    linkActivitySummeryData?.totalClicks /
                    linkActivitySummeryData?.totalMembers
                  ).toFixed(1)
                  : 0
                }}%
              </h2>
              <span class="link-activity-number ms-auto">Average among people who clicked</span>
            </div>
          </div>
        </div>
      </div>
      <Grid ref="grid" :class="'max-height-100-380 link-activity-table-wrap sorting-btn-th'"
        :data-items="linkActivityData" :take="take" :skip="skip" :total="total" :pageable="gridPageable"
        :columns="columns" @datastatechange="dataStateChange" :sortable="true" :sort="sort"
        @sortchange="sortChangeHandler">
        <GridNoRecords> No URLs found matching your input. </GridNoRecords>
        <toolbar>
          <div class="search-box-wrap width-220 me-3 mr-0-vsm w-100-vsm">
            <span class="k-icon k-i-zoom"></span>
            <span class="k-textbox k-grid-search k-display-flex">
              <k-input :class="'mb-0 width-220 w-100-vsm'" placeholder="Search by URL" :value="searchWord"
                @input="onSearch"></k-input>
            </span>
          </div>
          <div class="ms-auto w-100-vsm">
            <div class="d-flex w-100-vsm">
              <kbutton @click="exportReport" :class="'w-100-vsm'">Export</kbutton>
            </div>
          </div>
        </toolbar>
      </Grid>
    </div>
  </div>

  <div v-if="linkActivityData?.length == 0 && !searchText && !loading" class="min-h-100vh--188">
    <div class="dashboard-content bg-white radius-5 min-h-100vh--188 d-flex justify-content-center align-items-center">
      <div class="width-747 text-center">
        <component :is="'linkActivityBanner'"></component>
        <p class="color-light2 mt-3 mb-5">
          No links were used or clicked by your recipient in your email
          campaign.
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "./emailCampaignLinkActivity.scss";
</style>

<script>
import {
  getEmailCampaignLinkActivityService,
  getEmailCampaignLinkActivitySummeryService,
} from "@/services/clubDetails/emailCampaign.service";
import { Grid, GridToolbar, GridNoRecords } from "@progress/kendo-vue-grid";
import { Input } from "@progress/kendo-vue-inputs";
import { Button } from "@progress/kendo-vue-buttons";
import AppSnackBar from "../../../../common/AppSnackBar/AppSnackBar.vue";
import AppLoader from "@/components/common/AppLoader/AppLoader.vue";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { configuration } from "@/configurationProvider";
import { auth } from "@/services/auth.service";
import linkActivityBanner from "@/components/svg-icons/linkActivityBanner.vue";
import axios from "axios";
export default {
  name: "emailCampaignLinkActivity",
  components: {
    Grid,
    toolbar: GridToolbar,
    "k-input": Input,
    kbutton: Button,
    AppSnackBar,
    "app-loader": AppLoader,
    DropDownList,
    GridNoRecords,
    linkActivityBanner,
  },
  data: function () {
    return {
      loading: false,
      isActivitySummeryLoading: false,
      actionLoadingStatus: false,
      linkActivityData: [],
      linkActivitySummeryData: null,
      campaignId: this.$route.params.campaignId,

      searchWord: "",
      searchText: "",
      gridPageable: {
        buttonCount: 5,
        info: true,
        type: "numeric",
        pageSizes: [10, 50, 100, 200, 500, 1000],
        previousNext: true,
      },

      total: 0,
      skip: 0,
      take: 50,
      timer: null,

      notifyMessage: "",
      notifyType: "",
      isNotify: false,

      sort: [{ field: "url", dir: "asc" }],
    };
  },
  computed: {
    columns() {
      return [
        {
          field: "url",
          title: "URL",
        },
        {
          field: "uniqueClicks",
          title: "Unique Clicks",
        },
        {
          field: "totalClicks",
          title: "Total Clicks",
        },
      ];
    },
  },
  mounted() {
    const payload = {
      campaignId: this.campaignId,
      limit: this.take,
      offset: this.skip,
    };
    this.getEmailCampaignLinkActivity(payload);
    this.getEmailCampaignLinkActivitySummery(payload);
  },

  methods: {
    sortChangeHandler: function (e) {
      this.sort = e.sort;
      let sortBy = "";
      let sortDir = "";

      if (e.sort.length > 0) {
        sortBy = e.sort[0].field;
        sortDir = e.sort[0].dir;
      } else {
        sortBy = "";
        sortDir = "";
      }

      let payload = {
        campaignId: this.campaignId,
        limit: this.take,
        offset: this.skip,
        searchQuery: this.searchWord,
      };

      if (sortBy && sortDir) {
        payload.orderBy = `${sortBy} ${sortDir}`;
      }

      this.getEmailCampaignLinkActivity(payload);
    },

    getEmailCampaignLinkActivity(payload) {
      this.loading = true;
      payload = {
        ...payload,
        clubId: this.$route.params.id,
      };
      getEmailCampaignLinkActivityService(payload)
        .then((res) => {
          this.loading = false;
          this.linkActivityData = res.data?.data?.items;
          this.total = res.data?.data?.size || 0;
          this.take = res.data?.data?.limit || 0;
          this.skip = res.data?.data?.offset || 0;

          if (payload.searchQuery) {
            this.searchText = payload.searchQuery;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    getEmailCampaignLinkActivitySummery(payload) {
      this.isActivitySummeryLoading = true;
      payload = {
        ...payload,
        clubId: this.$route.params.id,
      };
      getEmailCampaignLinkActivitySummeryService(payload)
        .then((res) => {
          this.isActivitySummeryLoading = false;
          this.linkActivitySummeryData = res.data?.data;
        })
        .catch((err) => {
          this.isActivitySummeryLoading = false;
        });
    },

    dataStateChange(event) {
      this.take = event.data.take;
      this.skip = event.data.skip;

      let payload = {
        campaignId: this.campaignId,
        limit: event.data.take,
        offset: event.data.skip,
        searchQuery: this.searchWord,
      };

      this.getEmailCampaignLinkActivity(payload);
    },
    onSearch(e) {
      this.searchWord = e.value;
      this.isSearching = true;
      let payload = {
        campaignId: this.campaignId,
        // limit: this.take,
        // offset: this.skip,
        searchQuery: this.searchWord,
      };

      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.getEmailCampaignLinkActivity(payload);
      }, 500);
    },
    async exportReport() {
      this.actionLoadingStatus = true;
      const payload = {
        campaignId: this.campaignId,
      };

      let token = null;

      if (configuration.enableIdentityServer) {
        token = await auth.getAccessToken();
      } else {
        token = localStorage.getItem("clubeez_session");
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      axios({
        url: `${configuration.apiBaseUrl}/api/v1/Campaign/export-campaign-link-activity`,
        method: "POST",
        responseType: "blob",
        headers: headers,
        data: payload,
      })
        .then((response) => {
          this.actionLoadingStatus = false;

          let fileURL = window.URL.createObjectURL(new Blob([response.data]));
          let fileLink = document.createElement("a");
          fileLink.href = fileURL;
          let filename = `Campaign Link Activity.xlsx`;
          fileLink.setAttribute("download", filename);
          document.body.appendChild(fileLink);
          fileLink.click();

          this.showNotification(
            "Email campaign link activity exported.",
            "success"
          );
        })
        .catch((err) => {
          this.actionLoadingStatus = false;
        });
    },
    showNotification(message, type) {
      this.notifyMessage = message;
      this.notifyType = type;
      this.isNotify = true;
      setTimeout(
        () => (
          (this.notifyMessage = ""),
          (this.notifyType = "success"),
          (this.isNotify = false)
        ),
        4000
      );
    },
  },
};
</script>
